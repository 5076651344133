import React from "react";
import useCustomQuery from "../../Hooks/useCustomQuery";
import Loader from "../../components/Loader/Loader";
import InvoiceList from "./components/InvoiceList";

function Invoice() {
  document.title = "Kago Events | Ticket";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.data?.token;

  const {
    data: getMyInvoices,
    error: errorGetMyInvoices,
    isLoading,
  } = useCustomQuery(
    ["getInvoices", userId],
    `${process.env.REACT_APP_API}tickets-invoice/${userId}`,
    token
  );

  if (isLoading) {
    <div className="page-content mt-5 d-flex justify-content-center align-items-center">
      <Loader />
    </div>;
  }
  //   console;
  return (
    <div className="page-content">
      <InvoiceList invoices={getMyInvoices} />
    </div>
  );
}

export default Invoice;
