import React from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import HeaderNavigation from "../../../Layout/HorizontalLayout/CommunityHeader";
import logo from "../../../assets/images/community/Logo.png";
import Main from "./components/Main";

const Index = () => {
  document.title = "Kago Events | Event Set Up";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.data?.token;
  const params = useParams();

  const eventID = params.eventID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");

  return (
    <React.Fragment>
      <HeaderNavigation logoImg={logo} />
      <Box sx={{ backgroundColor: "#282e3f", mt: 0, p: 4 }} />
      <div className="" style={{ userSelect: "none" }}>
        <Main
          userId={userId}
          token={token}
          eventID={eventID}
          eventName={myEventName}
        />
      </div>
    </React.Fragment>
  );
};

export default Index;
