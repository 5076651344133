import React from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Spinner } from "reactstrap";
//icons

import "./booths.styles.scss";
import BoothCard from "./BoothCard";

const Booths = ({ userId, token, eventID }) => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.email;
  // add speaker

  const {
    data: getMyBoothDetails,
    error: errorGetMyBoothDetails,
    isFetching: isFetchingGetMyBoothDetailsl,
  } = useCustomQuery(
    ["myExhBoothDetails", eventID],
    `${process.env.REACT_APP_API}booths/?email=${userEmail}&eventId=${eventID}`,
    token
  );

  if (isFetchingGetMyBoothDetailsl) {
    return (
      <div className="page-content">
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className="booths-content">
      <Row>
        <BoothCard
          userId={userId}
          boothDetails={getMyBoothDetails}
          token={token}
          eventID={eventID}
        />
      </Row>
    </div>
  );
};
export default Booths;
