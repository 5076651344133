import React from "react";
import SimpleBar from "simplebar-react";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import { detectDeviceType } from "../../../utils/deviceType";
import Header from "./PublicOne/Header";
import About from "./PublicOne/About";
import Schedule from "./PublicOne/Schedule";
import Sponsors from "./PublicOne/Sponsors";
import OurSpeaker from "./PublicOne/Speakers";
import Faq from "./PublicOne/Faq";
import EventMap from "./PublicOne/EventMap";

// import ButtonStyle from "../../Buttons/ButtonSubmit";

const EventOne = ({ eventDetails, token, eventID }) => {
  const deviceType = detectDeviceType();

  const {
    data: getMySpeakers,
    error: errorGetMySpeakers,
    isLoading: isLoadingGetMySpeakers,
  } = useCustomFecthQuery(
    ["mySpeakers", eventID],
    `${process.env.REACT_APP_API}speakers/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getSessionAsGuest,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomFecthQuery(
    ["guestSessions", eventID],
    `${process.env.REACT_APP_API}guest/sessions?eid=${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomFecthQuery(
    ["guestSponsors", eventID],
    `${process.env.REACT_APP_API}guest/sponsors?eid=${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const getMyBooths = [];
  // const {
  //   data: getMyBooths,
  //   error: errorGetMyBooths,
  //   isLoading: isLoadingGetMyBooths,
  // } = useCustomFecthQuery(
  //   ["myBooths", eventID],
  //   `${process.env.REACT_APP_API}booths/event/${eventID}`,
  //   token,
  //   { enabled: !!eventID }
  // );
  // console.log("deviceType...", deviceType);
  // console.log("eventDetails...", eventDetails);
  // console.log("getMySpeakers...", getMySpeakers?.data?.length &&);

  return (
    <React.Fragment>
      <div className="new-event-landing-page">
        <Header
          eventDetails={eventDetails}
          deviceType={deviceType}
          isSessions={
            getSessionAsGuest?.status === "ok" &&
            getSessionAsGuest?.data?.length === 0
          }
          isSpeaker={
            getMySpeakers?.status === "ok" && getMySpeakers?.data?.length === 0
          }
          isFaq={eventDetails?.faq?.Length > 0}
          isSponsors={
            getMySponsors?.status === "ok" &&
            getMySponsors?.data?.length === 0 &&
            getMyBooths?.status === "ok" &&
            getMyBooths?.data?.length === 0
          }
        />
        <div className="w-100">
          <SimpleBar style={{ height: "83vh" }}>
            <div className="new-event-landing-page-banner">
              {eventDetails?.bannerImage ? (
                <img src={eventDetails?.bannerImage} alt="Event Banner" />
              ) : null}
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                paddingTop: "5%",
                paddingBottom: "5%",
              }}
            >
              <About eventDetails={eventDetails} deviceType={deviceType} />
            </div>
            {/* {getSessionAsGuest?.status === "ok" &&
            getSessionAsGuest?.data?.length === 0 ? null : (
              <div style={{ marginTop: "5%" }}>
                <Schedule
                  eventDetails={eventDetails}
                  token={token}
                  eventID={eventID}
                />
              </div>
            )} */}

            {getMySpeakers?.status === "ok" &&
            getMySpeakers?.data?.length === 0 ? null : (
              <div
                className="d-flex justify-content-center"
                style={{
                  backgroundColor: "white",
                  marginTop: "5%",
                  paddingTop: "5%",
                  paddingBottom: "5%",
                }}
              >
                <div
                  style={{
                    width: deviceType === "Mobile" ? "300px" : "1000px",
                  }}
                >
                  <OurSpeaker
                    token={token}
                    eventID={eventID}
                    getMySpeakers={getMySpeakers}
                  />
                </div>
              </div>
            )}
            {getMySponsors?.status === "ok" &&
            getMySponsors?.data?.length === 0 ? null : (
              <div style={{ marginTop: "5%", backgroundColor: "white" }}>
                {/* <Sponsors token={token} eventID={eventID} /> */}
              </div>
            )}

            {eventDetails?.faq?.Length > 0 ? (
              <div style={{ marginTop: "5%", backgroundColor: "white" }}>
                <Faq
                  token={token}
                  eventID={eventID}
                  eventDetails={eventDetails}
                />
              </div>
            ) : null}
            {eventDetails?.venueMap ? (
              <div style={{ marginTop: "5%", backgroundColor: "white" }}>
                <EventMap
                  venueMap={eventDetails?.venueMap}
                  deviceType={deviceType}
                />
              </div>
            ) : null}
          </SimpleBar>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventOne;
