import React, { useState, useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Spinner, Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import { BiEdit } from "react-icons/bi";

import "./css/Summary.scss";
import "./css/AddSponsor.scss";

const AddProducts = ({ token, boothID, eventID, setSlideInOpenProduct }) => {
  const queryClient = useQueryClient();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  // const userEmail = obj?.data?.data?.email;
  const key = ["myExbProducts", eventID];
  const mutation = useCustomMutation(key);
  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();
  const [inputs, setInputs] = useState({});

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    // setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `products/create/${boothID}`,
      isJSON = true;
    const formdata = new FormData();

    formdata.append("productImage", logoServer);
    formdata.append("productName", inputs.companyName);
    formdata.append("productDescription", inputs.description);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Product added successfully",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["exhibtitor-ProductsBooth", eventID]);
      setInputs({});
      setLogoServer(null);
      setSlideInOpenProduct(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <React.Fragment>
      <div className="sponsor-form-container">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="sponsor-form-body"
          onSubmit={handleSubmitForm}
        >
          <div className="sponsor-form-body__sponsor-logo">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={!logoServer ? ImgSession : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-45px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 250x250px | JPG, SVG, PNG | Up to 1 MB
              </p>
            </div>
          </div>

          <div className="sponsor-form-body__input">
            <label htmlFor="companyName">
              <p className="sponsor-form-body--input-heading">Product Name*</p>
            </label>
            <input
              type="text"
              id="companyName"
              placeholder=""
              value={inputs.companyName}
              className="sponsor-form-body__input--field"
              name="companyName"
              aria-label="companyName"
              onChange={handleChange}
            />
          </div>
          <div className="sponsor-form-body__input">
            <label htmlFor="description">
              <p className="sponsor-form-body--input-heading">
                Product Description
              </p>
            </label>
            <textarea
              name="description"
              value={inputs.description}
              onChange={(e) => handleChange(e)}
              placeholder="write..."
              className="w-100 mb-3 sponsor-form-body__input--field"
              rows={5}
              required
            ></textarea>
          </div>
          {/* <div className="sponsor-form-body__input">
            <label htmlFor="productBrochure">
              <p className="sponsor-form-body--input-heading">
                Product Brochure
              </p>
            </label>
            <input
              type="text"
              id="productBrochure"
              placeholder=""
              className="sponsor-form-body__input--field"
              name="productBrochure"
              aria-label="companyDescription"
              onChange={handleChange}
            />
          </div> */}
          <button className="btn-add-sponsor" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? <span>Add Product</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddProducts;
