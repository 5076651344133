import React from "react";
import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { detectDeviceType } from "../../../utils/deviceType";
import Header from "../EventRegistration/One/Header";
import CustomFooter from "../../../Layout/HorizontalLayout/CustomFooter";

import CheckoutTicketForm from "./CheckoutTicketForm";
import TicketDetails from "./TicketDetails";

const CheckoutTicket = () => {
  const params = useParams();
  const eventID = params?.eventID;
  const deviceType = detectDeviceType();
  const ticket = JSON.parse(localStorage.getItem("kagoTicketDetails"));

  const toggleSaveChanges = (e) => {
    e.preventDefault();
    //   const Method = "POST",
    //     url = `contacts/${userId}`,
    //     isJSON = true;
    //   var formdata = new FormData();
    //   formdata.append("photo", uploadImg);
    //   formdata.append("firstName", inputs.firstName);
    //   formdata.append("lastName", inputs.lastName);
    //   formdata.append("email", inputs.email);
    //   formdata.append("phoneNumber", inputs.phoneNumber);
    //   formdata.append("organization", inputs.organization);
    //   formdata.append("organizationAddress", inputs.organizationAddress);
    //   formdata.append("jobTitle", inputs.jobTitle);
    //   const data = {
    //     endPoint: url,
    //     bodyContent: formdata,
    //     Method: Method,
    //     displayMessage: "Business Card successfully created",
    //     token: token,
    //     isJSON: isJSON,
    //   };
    //   mutation.mutate(data);
  };

  //   console.log("...ticket....", ticket);

  return (
    <React.Fragment>
      <Header eventDetails={ticket?.eventDetails} deviceType={deviceType} />
      <div className="w-100 mt-2 d-flex justify-content-center">
        <Row>
          <Col md={8}>
            <div
              className="w-100 py-3 rounded-3"
              style={{ backgroundColor: "white" }}
            >
              <div className="px-4 py-2 h4">Checkout</div>
              <div className="px-4">
                <CheckoutTicketForm ticket={ticket} />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <TicketDetails ticket={ticket} eventID={eventID} />
            {/* <div className="w-100">
              <div className="h4">Ticket Details</div>
              <div
                className="mt-2"
                style={{ fontSize: 15, fontWeight: "bolder" }}
              >
                {ticket?.ticketName}
              </div>
              <div
                className="mt-2 mb-2 text-primary"
                style={{ fontSize: 22, fontWeight: "bolder" }}
              >
                R {ticket?.price}
              </div>
              {ticket?.ticketTerms ? (
                <>
                  <div style={{ fontSize: 18, fontWeight: "bold" }}>Note</div>
                  <div style={{ fontSize: 13 }}>{ticket?.ticketTerms}</div>
                </>
              ) : null}
            </div> */}
          </Col>
        </Row>
      </div>
      <div className="w-100">
        <CustomFooter />
      </div>
    </React.Fragment>
  );
};

export default CheckoutTicket;
