import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomFetchQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Spinner, Row, Col } from "reactstrap";
import formatDate from "../../../../utils/formatDate";

const AddTicket = ({ userID, token, eventID, setIsOffcanvasOpen }) => {
  //   const queryClient = useQueryClient();

  const [inputs, setInputs] = useState({});
  // const [ticketTerms, setTerms] = useState("");
  const [isEarlyBird, setIsEarlyBird] = useState(false);
  const key = ["setTicket", eventID];
  const mutation = useCustomMutation(key);

  const {
    data: getMyTicketSettings,
    error: errorGetMyTicketSettings,
    isFetching: isFetchingGetMyTicketSettings,
  } = useCustomFetchQuery(
    ["setTicket", eventID],
    `${process.env.REACT_APP_API}ticket-settings/${eventID}/event`,
    token,
    { enabled: !!eventID }
  );

  // console.log("getMyTicketSettings.....", getMyTicketSettings);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `ticket-settings?eid=${eventID}`,
      isJSON = null;
    const raw = JSON.stringify({
      tier: inputs.tier,
      price: parseInt(inputs.price),
      quantity: parseInt(inputs.quantity),
      discount: parseInt(inputs.discount),
      allowEarlyBird: isEarlyBird,
      earlyBirdStartDate: formatDate(inputs.earlyBirdStartDate),
      earlyBirdEndDate: formatDate(inputs.earlyBirdEndDate),
      closingDate: formatDate(inputs.closingDate),
      ticketTerms: inputs.ticketTerms,
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Ticket Successfully Created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
      // queryClient.invalidateQueries(["get-TicketSettings", eventID]);
      // handleTicket();
    }
  }, [mutation?.isSuccess]);

  return (
    <div className="w-100">
      <form className="" onSubmit={handleSubmitForm} style={{}}>
        <Row>
          <Col md={4}>
            <label className="">Ticket Name</label>
            <input
              type="text"
              name="tier"
              className="mb-3 form-control fromInput"
              value={inputs?.tier}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={4}>
            <label className="">Price </label>
            <input
              type="number"
              name="price"
              value={inputs?.price}
              className="mb-3 form-control fromInput w-100"
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={4}>
            <label className="">Quantity </label>
            <input
              type="number"
              name="quantity"
              value={inputs?.quantity}
              className="mb-3 form-control fromInput w-100"
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label>Ticket Cloding Date</label>
            <input
              type="date"
              name="closingDate"
              value={inputs.closingDate}
              onChange={handleChange}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
        </Row>

        <div>
          <input
            className="form-check-input"
            type="checkbox"
            // name="formatRadio"
            id="exampleRadios1"
            // value={true}
            // onChange={handleIsEarlyBird}
            onClick={() => setIsEarlyBird(!isEarlyBird)}
            setIsEarlyBird
            checked={isEarlyBird}
          />
          <label className="mx-2">Include Early Bird Price</label>
        </div>

        {isEarlyBird ? (
          <div className="mt-2">
            <Row>
              <Col md={4}>
                <label>Early Bird Start Date </label>
                <input
                  type="date"
                  name="earlyBirdStartDate"
                  value={inputs.earlyBirdStartDate}
                  onChange={handleChange}
                  className="mb-3 form-control fromInput"
                  required
                />
              </Col>
              <Col md={4}>
                <label>Early Bird End Date</label>
                <input
                  type="date"
                  name="earlyBirdEndDate"
                  value={inputs.earlyBirdEndDate}
                  onChange={handleChange}
                  className="mb-3 form-control fromInput"
                  required
                />
              </Col>
              <Col md={4}>
                <label>Discount (%) </label>
                <input
                  type="number"
                  name="discount"
                  value={inputs.discount}
                  onChange={handleChange}
                  className="mb-3 form-control fromInput"
                  required
                />
              </Col>
            </Row>
          </div>
        ) : null}
        <Row>
          <Col md={12}>
            <label>Refund Policy </label>
            <textarea
              rows={5}
              className="form-control"
              name="ticketTerms"
              value={inputs.ticketTerms}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <div className="d-flex mt-2 justify-content-center align-item-center">
          <button className="btn btn-primary" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? <span>Create</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </div>
      </form>
    </div>
  );
};
export default AddTicket;
