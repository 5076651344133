import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
// import ViewAttendee from "./ViewAttendee";
import ModalDelete from "../../../Modal/ModalDelete";
// import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import { AiFillEye } from "react-icons/ai";

const TableSms = ({ userID, token, eventID, mySMS }) => {
  const [attendeeID, setAttendeeID] = useState(false);
  const [attendEmail, setAttendeeEmail] = useState(null);
  const [smsDetails, setSMSDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["allSMS", eventID];

  const mutationDelete = useCustomMutation(key);
  // console.log("sms....", key);
  // console.log("eventID....", eventID);
  // const {
  //   data: getAttendees,
  //   error: errorGetAttendees,
  //   isLoading: isLoadingGeAttendees,
  // } = useCustomQuery(
  //   key,
  //   `${process.env.REACT_APP_API}attendees/${eventID}/invited`,
  //   token
  // );

  const tog_viewSMS = (details) => {
    setSMSDetails(details);
    setIsToggleView(!isToggleView);
  };
  const tog_deleteSMS = (id, email) => {
    setAttendeeID(id);
    setAttendeeEmail(email);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `attendees/${attendeeID}/?eventId=${eventID}&email=${attendEmail}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Attendee Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");
    return `${hh}:${mm}`;
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Audience</span>,
      selector: (row) => row?.audience,
    },
    {
      name: <span className="font-weight-bold fs-13">Message</span>,
      selector: (row) => row?.body,
    },
    {
      name: <span className="font-weight-bold fs-13">Sent By</span>,
      cell: (row) => {
        return (
          <div>
            <span>{row?.userId?.firstName}</span>
            <span className="mx-1">
              {" "}
              {row?.userId?.lastName?.substring(0, 1)}
            </span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      selector: (row) => formatDate(row?.createdAt),
    },
    {
      name: <span className="font-weight-bold fs-13">Time</span>,
      selector: (row) => formatTime(row?.createdAt),
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className=""
                onClick={() => tog_viewSMS(row)}
              />
            </div>

            {/* <div>
              <AiFillDelete
                size={20}
                className=""
                onClick={() => tog_deleteSMS(row?._id, row?._id)}
              />
            </div> */}
          </div>
        );
      },
    },
  ];

  const data = mySMS;

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card-mb-none">
        <div className="w-100">
          {/* <div style={{ fontSize: 16, fontWeight: "600" }}>SMS</div> */}
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Message"
        body={
          <h1>Delete</h1>
          // <DeleteForm
          //   setIsToggleDelete={setIsToggleDelete}
          //   DeleteParticitant={DeleteParticitant}
          //   pending={mutationDelete?.isLoading}
          // />
        }
      />

      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="SMS"
        body={
          <div>
            <div>
              <span style={{ fontWeight: "bold" }}>To :</span>{" "}
              <span>{smsDetails?.audience}</span>
            </div>

            <div
              className="mt-2 rounded-3 p-2 text-justify"
              style={{
                border: "0.5px solid #CACACA",
                backgroundColor: "#F0F0F0",
                minHeight: 200,
              }}
            >
              {smsDetails?.body}
            </div>
          </div>
        }
        toggleSaveChanges={() => tog_viewSMS()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default TableSms;

{
  /* <ViewAttendee details={attendDetails} */
}
// /></div>
