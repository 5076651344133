import React from "react";
import { Row, Container, Spinner } from "reactstrap";
import AllExploreEvents from "./AllExploreEvents";

const ExploreEvent = ({ t, search }) => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.email;
  const userRole = obj?.data?.data?.roles;
  const token = obj?.data?.token;

  // console.log("user role....", obj?.data?.data?.roles);

  const isEventLoading = false;

  if (isEventLoading) {
    return (
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Container>
        <div>
          <Row>
            <AllExploreEvents
              search={search}
              userEmail={userEmail}
              token={token}
              t={t}
            />
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default ExploreEvent;
