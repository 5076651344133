import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import { FaSave, FaPlus } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import ViewRegistration from "./ViewRegistration";

const CreateRegistration = () => {
  const [formContent, setFormContent] = useState([
    { label: "First name", list: [], name: "firstName", question_type: "text" },
    { label: "Last name", list: [], name: "lastName", question_type: "text" },
    { label: "Email", list: [], name: "email", question_type: "email" },
    { label: "Phone number", list: [], name: "email", question_type: "text" },
  ]);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [textField, setTextField] = useState("");
  const [editedField, setEditedField] = useState("");

  const toggleSaveChanges = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const addQuestion = () => {
    const field = {
      name: `question_${formContent.length}`,
      label: "Untitled question",
      question_type: "short_answer", // "paragraph", "options",text,options
      list: [],
    };
    setFormContent([...formContent, field]);
  };

  const editField = (fieldName, fieldLabel) => {
    const formFields = [...formContent];
    const fieldIndex = formFields.findIndex((f) => f.name === fieldName);
    if (fieldIndex > -1) {
      formFields[fieldIndex].label = fieldLabel;
      setFormContent(formFields);
    }
  };

  const editFieldType = (fieldName, fieldLabel) => {
    const formFields = [...formContent];
    const fieldIndex = formFields.findIndex((f) => f.name === fieldName);
    if (fieldIndex > -1) {
      formFields[fieldIndex].question_type = fieldLabel;
      setFormContent(formFields);
    }
  };

  const addFieldOption = (fieldName, option) => {
    const formFields = [...formContent];
    const fieldIndex = formFields.findIndex((f) => f.name === fieldName);
    if (fieldIndex > -1) {
      if (option && option !== "") {
        formFields[fieldIndex].list.push(option);
        setFormContent(formFields);
        setTextField("");
      }
    }
  };

  console.log("formContent...", formContent);
  //   console.log("formContent[0].label...", formContent[0]);

  return (
    <div
      className="mt-3 rounded-3"
      style={{ width: "75%", backgroundColor: "white" }}
    >
      <div className="w-100 d-flex justify-content-center align-items-center mt-2 ">
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ width: "64%", fontSize: 18, fontWeight: "600" }}
        >
          {/* Create Registration Form */}
        </div>

        <div
          className="d-flex justify-content-end align-items-center px-4 py-2"
          style={{ width: "36%" }}
        >
          <ButtonIcon
            Title="Save"
            BackgroundColor="#f68a20"
            ColorText="#fff"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={toggleSaveChanges}
            Icon={<FaSave color="white" />}
            pending={false}
          />
        </div>
      </div>
      <div className="w-100 px-4 mb-2">
        <Row>
          <Col md={6}>
            <div className="w-100">
              <label className="text-dark">{formContent[0].label}</label>
              <input
                type="text"
                value={formContent[0].label}
                onBlur={() => {
                  setOnEdit(false);
                  setEditedField("");
                }}
                className="form-control"
                disabled
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="w-100  mb-2">
              <div className="w-100">
                <label className="text-dark">{formContent[1].label}</label>
                <input
                  type="text"
                  value={formContent[1].label}
                  onBlur={() => {
                    setOnEdit(false);
                    setEditedField("");
                  }}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="w-100  mb-2">
              <div className="w-100">
                <label className="text-dark">{formContent[2].label}</label>
                <input
                  type="text"
                  value={formContent[2].label}
                  onBlur={() => {
                    setOnEdit(false);
                    setEditedField("");
                  }}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="w-100 mb-2">
              <div className="w-100  mb-2">
                <div className="w-100">
                  <label className="text-dark">{formContent[3].label}</label>
                  <input
                    type="text"
                    value={formContent[3].label}
                    onBlur={() => {
                      setOnEdit(false);
                      setEditedField("");
                    }}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="w-100 px-4">
        {formContent?.slice(4)?.map((field) => {
          return (
            <div>
              <div className="flex justify-content-between align-items-center">
                <div key={field.name}>
                  {onEdit && editedField === field.name ? (
                    <input
                      type="text"
                      value={field.label}
                      onChange={(e) => editField(field.name, e.target.value)}
                      onBlur={() => {
                        setOnEdit(false);
                        setEditedField("");
                      }}
                      className="form-control"
                    />
                  ) : (
                    <label
                      onClick={() => {
                        setOnEdit(true);
                        setEditedField(field.name);
                      }}
                    >
                      {field.label}
                    </label>
                  )}
                </div>
                <div>
                  <select
                    onChange={(e) => editFieldType(field.name, e.target.value)}
                    className="form-control"
                  >
                    <option value="short_answer">Short Answer</option>
                    <option value="text">Text</option>
                    <option value="paragraph">Paragraph</option>
                    <option value="options">Options</option>
                  </select>
                </div>
              </div>

              <div className="my-4">
                {field.question_type === "text" && (
                  <input
                    type="text"
                    className="w-100 form-control"
                    placeholder={field.label}
                  />
                )}
                {field.question_type === "short_answer" && (
                  <input
                    type="text"
                    className="w-100 form-control"
                    placeholder={field.label}
                  />
                )}
                {field.question_type === "paragraph" && (
                  <textarea
                    rows={5}
                    className="form-control"
                    placeholder={field.label}
                  />
                )}
                {field.question_type === "options" && (
                  <div className="my-4">
                    <div className="d-flex">
                      <div>
                        <input
                          type="text"
                          onChange={(e) => setTextField(e.target.value)}
                          value={textField}
                          placeholder="Add an option"
                          className="form-control"
                        />
                      </div>
                      <div className="d-flex justify-content-center align-items-center mx-2">
                        <AiOutlinePlusCircle
                          size={30}
                          color="#6D6D6D"
                          onClick={() => addFieldOption(field.name, textField)}
                        />
                      </div>
                      <FaPlus color="white" />
                    </div>
                    <div className="w-100 mt-3">
                      <select className="px-5 form-control">
                        <option>Select...</option>
                        {field.list.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="w-100 d-flex justify-content-center align-items-center p-2 my-3">
        <ButtonIcon
          Title="Add Field"
          BackgroundColor="#000"
          ColorText="#fff"
          BorderColor="#000"
          borderRadius={20}
          handleOnclick={addQuestion}
          Icon={<FaPlus color="white" />}
        />
      </div>
      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="Preview Registration Form"
        body={<ViewRegistration formContent={formContent} />}
        toggleSaveChanges={() => toggleSaveChanges()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default CreateRegistration;
