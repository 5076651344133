import PropTypes from "prop-types";
import React from "react";
import withRouter from "../../components/Common/withRouter";
import Slide from "../../components/Authentication/Slide";
import ParticipantForm from "./components/Participant/ParticipantForm";

const AttendeeReg = () => {
  document.title = "Kago Events| Login";
  return (
    <div className="authentication-container">
      <div className="authentication--wrapper">
        <div
          className="p-2 login--form--slider-container d-flex"
          style={
            {
              // backgroundColor: "purple",
            }
          }
        >
          <div className="login--form--slider-container--first"></div>

          <div
            className="login--form--slider-container--second d-flex align-items-center"
            style={{
              userSelect: "none",
            }}
          >
            <div className="h-100 d-flex align-items-center">
              <div
                className="w-100"
                style={{
                  userSelect: "none",
                  // backgroundColor: "yellow",
                }}
              >
                <div className="w-100 d-flex justify-content-center ">
                  <ParticipantForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login--slider-container">
          <div className="rounded-5 login--slider-wrapper">
            <Slide />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeReg;
