import React from "react";
import useCustomQuery from "../..//Hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import EventOne from "../../components/Common/EventRegistration/EventOne";

const EventRegistration = () => {
  document.title = "Kago Event | Registration";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const params = useParams();
  const eventID = params?.eventID;

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myPublicEventDetail", eventID],
    `${process.env.REACT_APP_API}guest/events/${eventID}`,
    null
  );

  // console.log("getMyEventDetail...", getMyEventDetail?.data);

  return (
    <React.Fragment>
      <EventOne eventDetails={getMyEventDetail?.data} />
    </React.Fragment>
  );
};

export default EventRegistration;
