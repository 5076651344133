import React from "react";
import { useNavigate } from "react-router-dom";
import { CardBody } from "reactstrap";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import ButtonGradient from "../../Buttons/ButtonGradient";

const BuyTickets = ({ eventDetails, eventID }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const token = obj?.data?.token;
  const key = ["setTicket", eventID];
  const {
    data: getMyTicketSettings,
    error: errorGetMyTicketSettings,
    isLoading: isLoadingGetMyTicketSettings,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}ticket-settings/${eventID}/event`,
    token,
    {
      enabled: !!eventID,
    }
  );

  const handleBuyTicket = (price, ticketName, ticketTerms) => {
    localStorage.setItem(
      "kagoTicketDetails",
      JSON.stringify({
        price: price,
        ticketName: ticketName,
        ticketTerms: ticketTerms,
        eventDetails: eventDetails,
      })
    );
    Navigate(`/checkout/${eventID}`);
  };

  const today = new Date();

  return (
    <>
      {getMyTicketSettings?.data?.map((ticket, i) => {
        const earlyBirdStartDate = new Date(ticket.earlyBirdStartDate);
        const earlyBirdEndDate = new Date(ticket.earlyBirdEndDate);
        const closingDate = new Date(ticket.closingDate);
        const isEarlyBirdActive =
          ticket.allowEarlyBird &&
          today >= earlyBirdStartDate &&
          today <= earlyBirdEndDate;
        const isEventClosed = today >= closingDate;
        const isSoldOut = ticket.quantitySold === ticket.quantity;

        // Determine the price to pass to handleBuyTicket
        const ticketPrice = isEarlyBirdActive
          ? ticket?.tierEarlyBirdPrice
          : ticket?.price;

        return (
          <div className="w-100 mt-2 d-flex justify-content-center" key={i}>
            <div style={{ width: "45%", position: "relative" }}>
              <CardBody
                className="p-3 rounded-3 card-hover" // Added class for hover effect
                style={{
                  backgroundColor: "white",
                  position: "relative",
                  paddingLeft: "20px",
                  overflow: "hidden",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    width: "5px",
                    background: "linear-gradient(to bottom, #f7983c, #ff7c6e)",
                    content: '""',
                  }}
                ></div>
                <div className="w-100 d-flex justify-content-between">
                  <div style={{ fontSize: 16, fontWeight: "bolder" }}>
                    {ticket?.tier}
                  </div>

                  <div>
                    {isEventClosed || isSoldOut ? (
                      <ButtonGradient
                        Title={isSoldOut ? "Sold Out" : "Not Available"}
                        leftColor="#a9a9a9"
                        rightColor="#a9a9a9"
                        ColorText="white"
                        BorderColor="transparent"
                        borderRadius={50}
                        handleOnclick={() => {}}
                      />
                    ) : (
                      <ButtonGradient
                        Title="Buy Now"
                        leftColor="#f7983c"
                        rightColor="#ff7c6e"
                        ColorText="white"
                        BorderColor="transparent"
                        borderRadius={50}
                        handleOnclick={() =>
                          handleBuyTicket(
                            ticketPrice,
                            ticket?.tier,
                            ticket?.ticketTerms
                          )
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="w-100">
                  <span
                    style={{
                      fontSize: 22,
                      fontWeight: "bolder",
                      color: "black",
                      textDecoration: isEarlyBirdActive
                        ? "line-through"
                        : "none",
                    }}
                  >
                    R {ticket?.price}
                  </span>{" "}
                  {isEarlyBirdActive && (
                    <sup
                      style={{
                        fontSize: 18,
                        fontWeight: "bolder",
                        background:
                          "linear-gradient(to right, #f7983c, #ff7c6e)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      R{ticket?.tierEarlyBirdPrice}
                    </sup>
                  )}
                </div>
                <div className="mt-2">{ticket?.ticketTerms}</div>
              </CardBody>
            </div>
          </div>
        );
      })}
      <style jsx>{`
        .card-hover:hover {
          transform: scale(1.05); /* Slight zoom effect */
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effect */
        }
      `}</style>
    </>
  );
};

export default BuyTickets;
