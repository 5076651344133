import React, { useState } from "react";
import useCustomQuery from "../../../../../Hooks/useCustomQuery";
// import useCustomMutation from "../../../../../hooks/useCustomMutation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "reactstrap";
//icons

import { useNavigate } from "react-router-dom";

const Reception = ({ token, userId, eventID, eventDetail }) => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userName = obj?.data?.data?.firstName;
  const userEmail = obj?.data?.data?.email;

  // add sponsor
  const [slideInOpenSession, setSlideInOpenSession] = useState(false);
  const [slideInOpenSpeaker, setSlideInOpenSpeaker] = useState(false);
  // const [slideInOpenSponsor, setSlideInOpenSponsor] = useState(false);
  const [slideInOpenBooth, setSlideInOpenBooth] = useState(false);

  const toggleSlideInBooth = () => {
    setSlideInOpenBooth(!slideInOpenBooth);
  };

  const {
    data: getMyTicketAsAttendee,
    error: errorGetMyTicketAsAttendee,
    isLoading: isLoadingGetMyTicketAsAttendee,
  } = useCustomQuery(
    ["MyTicketAsAttendee", eventID],
    `${process.env.REACT_APP_API}tickets/${eventID}/?email=${userEmail}`,
    token
  );

  const ticketType =
    getMyTicketAsAttendee && getMyTicketAsAttendee.length > 0
      ? getMyTicketAsAttendee[0]?.ticketType.toLowerCase()
      : null;

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/user/${eventID}/?access=${ticketType}`,
    token
  );

  // console.log("getMyPlatinum..", getMyPlatinum);

  if (isLoadingGetMyTicketAsAttendee) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  if (isLoadingGetMySessions) {
    <div className="mt-5 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  }

  // console.log("getMyTicketAsAttendee....Type", getMyTicketAsAttendee);

  const handleJoinSession = (sessionName, sessionID) => {
    if (eventDetail?.eventType?.includes("Conference")) {
      Navigate(
        `/live-session/attendee/${eventDetail?.eventName?.replace(
          / /g,
          "-"
        )}/${sessionName?.replace(/ /g, "-")}/${eventID}/${sessionID}`
      );
    }

    if (eventDetail?.eventType?.includes("Social")) {
      Navigate(
        `/live-session/social/attendee/${eventDetail?.eventName?.replace(
          / /g,
          "-"
        )}/${sessionName?.replace(/ /g, "-")}/${eventID}/${sessionID}`
      );
    }
  };

  // Render sessions based on the count
  const renderSessions = () => {
    if (!getMySessions || getMySessions.length === 0) {
      return null;
    }

    if (getMySessions.length === 1) {
      {
        getMySessions.map((session, i) => {
          // console.log("session...", session);
          return (
            <div
              key={i}
              className="event-details__upcoming__sessions--session"
              style={{
                border: "1px solid #828283",
                borderRadius: "12px",
                width: "100%",
                position: "relative",
                color: "#c2c2c3",
                padding: "16px",
                background: "rgba(255, 255, 255, 0.1)",
                marginBottom: "16px",
              }}
            >
              <p style={{ color: "white", fontWeight: 600, fontSize: "13px" }}>
                {session?.startTime} - {session?.endTime}
              </p>
              <h4
                style={{
                  color: "white",
                  fontWeight: 600,
                  margin: "-4px 0 10px",
                }}
              >
                {session?.sessionName}
              </h4>
              <div className="d-flex">
                {session?.speakers?.map((speaker, i) => {
                  // console.log("speaker..", speaker);
                  return (
                    <div
                      className="d-flex justify-content-center align-items-center h5"
                      key={i}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: "#1a87a3",
                        color: "white",
                        marginLeft: "-7px",
                        zIndex: i,
                        border: "2px solid white",
                      }}
                    >
                      {speaker?.profileImage ? (
                        <img
                          src={speaker?.profileImage}
                          alt="speaker-picture"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <>{speaker?.firstName}</>
                      )}
                    </div>
                  );
                })}
              </div>

              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <span
                  style={{
                    backgroundColor: "#2d2c32",
                    border: "0.8px solid #5c5c5c",
                    borderRadius: "8px",
                    padding: "4px 8px",
                    fontSize: "12px",
                  }}
                >
                  Session
                </span>
                <span> &#8226;</span>
                {session?.tags?.map((tag, i) => {
                  return (
                    <div key={i} className="d-flex">
                      <span style={{ fontSize: "13px", marginRight: "5px" }}>
                        #{tag}
                      </span>
                    </div>
                  );
                })}
              </div>

              <button
                style={{
                  position: "absolute",
                  zIndex: "999",
                  top: "5%",
                  right: "5%",
                  backgroundColor: "#2dd173",
                  color: "white",
                  fontWeight: "600",
                  letterSpacing: "0.8px",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "7px",
                }}
                onClick={() =>
                  handleJoinSession(session?.sessionName, session?._id)
                }
              >
                Join now
              </button>
            </div>
          );
        });
      }
    }

    return getMySessions?.data?.slice(0, 2).map((session, i) => {
      // console.log("session..", session);
      // <div key={session.id} className="session">
      //   <h3>{session.sessionName}</h3>
      //   <p>{session.sessionDescription}</p>
      //   {/* Render other details of each session as needed */}
      // </div>
      return (
        <div
          key={i}
          className="event-details__upcoming__sessions--session"
          style={{
            border: "1px solid #828283",
            borderRadius: "12px",
            width: "100%",
            position: "relative",
            color: "#c2c2c3",
            padding: "16px",
            background: "rgba(255, 255, 255, 0.1)",
            marginBottom: "16px",
          }}
        >
          <p style={{ color: "white", fontWeight: 600, fontSize: "13px" }}>
            {session?.startTime} - {session?.endTime}
          </p>
          <h4
            style={{
              color: "white",
              fontWeight: 600,
              margin: "-4px 0 10px",
            }}
          >
            {session?.sessionName}
          </h4>
          <div className="d-flex">
            {session?.speakers?.map((speaker, i) => {
              // console.log("speaker..", speaker);
              return (
                <div
                  className="d-flex justify-content-center align-items-center h5"
                  key={i}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: "#1a87a3",
                    color: "white",
                    marginLeft: "-7px",
                    zIndex: i,
                    border: "2px solid white",
                  }}
                >
                  {speaker?.profileImage ? (
                    <img
                      src={speaker?.profileImage}
                      alt="speaker-picture"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <>{speaker?.firstName}</>
                  )}
                </div>
              );
            })}
          </div>

          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <span
              style={{
                backgroundColor: "#2d2c32",
                border: "0.8px solid #5c5c5c",
                borderRadius: "8px",
                padding: "4px 8px",
                fontSize: "12px",
              }}
            >
              Session
            </span>
            <span> &#8226;</span>
            {session?.tags?.map((tag, i) => {
              return (
                <div key={i} className="d-flex">
                  <span style={{ fontSize: "13px", marginRight: "5px" }}>
                    {tag}
                  </span>
                </div>
              );
            })}
          </div>

          <button
            style={{
              position: "absolute",
              zIndex: "999",
              top: "5%",
              right: "5%",
              backgroundColor: "#2dd173",
              color: "white",
              fontWeight: "600",
              letterSpacing: "0.8px",
              padding: "8px 16px",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={() =>
              handleJoinSession(session?.sessionName, session?._id)
            }
          >
            Join now
          </button>
        </div>
      );
    });
  };
  // console.log("eventtttttttttt", eventDetail);

  return (
    <div
      style={{
        background: "#131216",
      }}
    >
      <div className="reception-content">
        <div className="community">
          <div
            className="community-banner"
            style={{ backgroundImage: `url(${eventDetail?.bannerImage})` }}
          >
            <div className="community-content">
              <div className="community-content__wrapper">
                <h6>{eventDetail?.eventName}</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="event-details">
          <div className="event-details__welcome">
            <h2 style={{ color: "white", margin: "24px 0" }}>
              Welcome, {userName}
            </h2>
            <p
              style={{ color: "#c2c2c3", marginTop: "-12px", fontSize: "13px" }}
            >
              {eventDetail?.eventDescription}
            </p>
            {eventDetail?.videoLink ? (
              <iframe
                width="560"
                height="387"
                src={eventDetail?.videoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                style={{ border: "0.4px solid #8f8f90", borderRadius: "12px" }}
              ></iframe>
            ) : null}
          </div>
          <div className="event-details__upcoming">
            <h2 style={{ color: "white", margin: "24px 0" }}>Upcoming</h2>
            <p
              style={{ color: "#c2c2c3", marginTop: "-12px", fontSize: "13px" }}
            >
              Explore what's about to come next from the session summary below.
            </p>
            <div className="event-details__upcoming__sessions">
              {renderSessions()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Reception;
