import React from "react";
import { useParams } from "react-router-dom";

import ReceptionHeader from "./reception-header/reception-header.component";

const Main = () => {
  document.title = "Kago Events | Event Reception";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.data?.token;
  const params = useParams();

  const eventID = params.eventID;

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ userSelect: "none", backgroundColor: "rgb(19, 18, 22)" }}
      >
        <ReceptionHeader userId={userId} token={token} eventID={eventID} />
      </div>
    </React.Fragment>
  );
};
export default Main;
