import React, { useState, useContext } from "react";
import CryptoJS from "crypto-js"; // Import crypto-js
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { UserTicketContext } from "../../../helpers/UserTicketContext";

function CheckoutTicketForm({ ticket }) {
  const { customerDetails, setCustomerDetails } = useContext(UserTicketContext);
  const [inputs, setInputs] = useState({
    name_first: "",
    name_last: "",
    phoneNumber: "",
    address: "",
  });

  //   console.log("ticket...", ticket?.price);

  const generateSignature = (data, passPhrase = null) => {
    let parameterString = "";

    Object.keys(data).forEach((key) => {
      if (data[key]) {
        parameterString += `${key}=${encodeURIComponent(
          data[key].trim()
        ).replace(/%20/g, "+")}&`;
      }
    });

    // Remove the trailing ampersand
    let signatureString = parameterString.slice(0, -1);

    if (passPhrase) {
      signatureString += `&passphrase=${encodeURIComponent(
        passPhrase.trim()
      ).replace(/%20/g, "+")}`;
    }

    // Generate MD5 hash using crypto-js
    return CryptoJS.MD5(signatureString).toString(CryptoJS.enc.Hex);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setCustomerDetails({
      name_first: inputs.name_first,
      name_last: inputs.name_last,
      phoneNumber: inputs.phoneNumber,
      email_address: inputs.email_address,
    });
    // localStorage.setItem(
    //   "kagoTicketUserForm",
    //   JSON.stringify({
    //     firstName: "jed",
    //     lastName: "kazadi",
    //     phoneNumber: inputs.phoneNumber,
    //     email: inputs.email_address,
    //   })
    // );

    // const merchantData = {
    //   merchant_id: "10000100",
    //   merchant_key: "46f0cd694581a",
    //   return_url: "https://events.kago.digital/",
    //   cancel_url:
    //     "https://618a6f38f98e3482ade07fbaeda5c8ee.serveo.net/cancel.html",
    //   notify_url:
    //     "https://618a6f38f98e3482ade07fbaeda5c8ee.serveo.net/notify.html",
    //   name_first: "jed",
    //   name_last: "kazadi",
    //   email_address: "jed@gmail.com",
    //   m_payment_id: "1234",
    //   amount: `400`,
    //   item_name: `#008`,
    //   // amount: `${ticket?.price}`,
    //   // item_name: `${ticket?.ticketName}`,
    //   phone_number: "+2778657818",
    //   // address: inputs.address,
    // };

    // const passphrase = "jt7NOE43FZPn";
    // merchantData.signature = generateSignature(merchantData, passphrase);

    // const form = document.createElement("form");
    // form.action = "https://sandbox.payfast.co.za/eng/process";
    // form.method = "post";

    // Object.entries(merchantData).forEach(([key, value]) => {
    //   if (value) {
    //     const hiddenInput = document.createElement("input");
    //     hiddenInput.type = "hidden";
    //     hiddenInput.name = key;
    //     hiddenInput.value = value.trim();
    //     form.appendChild(hiddenInput);
    //   }
    // });

    // document.getElementById("checkout-container").appendChild(form);
    // form.submit();
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <form
        noValidate
        autoComplete="off"
        id="checkout-container"
        onSubmit={handleNext}
      >
        <Row>
          {/* First Name Field */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>First Name
            </label>
            <input
              type="text"
              name="name_first"
              value={inputs.name_first}
              placeholder="Enter First Name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control"
              required
            />
          </Col>

          {/* Last Name Field */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Last Name
            </label>
            <input
              type="text"
              name="name_last"
              value={inputs.name_last}
              placeholder="Enter Last Name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control"
              required
            />
          </Col>

          {/* Phone Number Field */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Phone Number
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>

          {/* Physical Address Field */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Email
            </label>
            <input
              type="email"
              name="email_address"
              value={inputs.email_address}
              placeholder="Enter Email Address"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control"
              required
            />
          </Col>

          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Next"
              ColorText="white"
              BorderColor="#000"
              BackgroundColor="#000"
              borderRadius="25px"
              handleOnclick={handleNext}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default CheckoutTicketForm;
