import React, { useState, useEffect } from "react";
import { eventCountDown } from "../../../../utils/eventCountDown";

import Divider from "./Divider";
// import GradientComponent from "../../GradientColor/GradientComponent";
import ButtonGradient from "../../../Buttons/ButtonGradient";
const About = ({ eventDetails, deviceType }) => {
  const [countDown, setCountDown] = useState({ status: null, count: null });

  useEffect(() => {
    if (eventDetails) {
      const intervalId = setInterval(() => {
        const count = eventCountDown(
          eventDetails?.startDate,
          eventDetails?.endDate
        );
        setCountDown(count);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [eventDetails]);

  // console.log("eventDetails...", eventDetails);
  // console.log("countDown...", countDown);

  return (
    <React.Fragment>
      <div className="w-100" style={{ backgroundColor: "white" }}>
        <h1
          id="about"
          className="d-flex justify-content-center"
          style={{ color: "black", fontWeight: "bold" }}
        >
          About Event
        </h1>
        <Divider />
        <div className="w-100 d-flex justify-content-center">
          <p
            style={{
              marginTop: 15,
              width: deviceType === "Mobile" ? "90%" : "67%",
              textAlign: "center",
            }}
          >
            {eventDetails?.eventDescription}
          </p>
        </div>
        {countDown?.status === "Completed" ? (
          <>
            {/* <div className="w-100 d-flex justify-content-center p-2">
              This event is
            </div> */}
            <div className="w-100 d-flex justify-content-center p-2">
              <ButtonGradient
                Title="Event Completed"
                leftColor="#f7983c"
                rightColor="#ff7c6e"
                ColorText="white"
                BorderColor="transparent"
                borderRadius={50}
                handleOnclick={() => {}}
              />
            </div>
            {/* <div className="w-100 mt-2 d-flex justify-content-center">
              <h4>Event Will Start In</h4>
            </div> */}
          </>
        ) : (
          <>
            <div className="w-100 mt-2 d-flex justify-content-center">
              <h4>Event Will Start In</h4>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <div
                className="mt-2 d-flex justify-content-center align-items-center"
                style={{ width: deviceType === "Mobile" ? 200 : 400 }}
              >
                <div
                  className="mx-2"
                  style={{
                    width: deviceType === "Mobile" ? 30 : 90,
                    height: deviceType === "Mobile" ? 30 : 90,
                    borderWidth: 2,
                    borderColor: "#f68a20",
                    borderRadius: 10,
                  }}
                >
                  <h1
                    className="w-100 d-flex justify-content-center align-items-center"
                    style={{
                      fontSize: deviceType === "Mobile" ? 12 : null,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {countDown?.data?.days}
                  </h1>
                  <div
                    className="w-100 d-flex justify-content-center"
                    style={{
                      fontSize: deviceType === "Mobile" ? 9 : 16,
                      color: "black",
                    }}
                  >
                    DAYS
                  </div>
                </div>
                <div
                  className="mx-2"
                  style={{
                    width: deviceType === "Mobile" ? 30 : 90,
                    height: deviceType === "Mobile" ? 30 : 90,
                    borderWidth: 2,
                    borderColor: "#f68a20",
                    borderRadius: 10,
                  }}
                >
                  <h1
                    className="w-100 d-flex justify-content-center align-items-center"
                    style={{
                      fontSize: deviceType === "Mobile" ? 12 : null,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {countDown?.data?.hours}
                  </h1>
                  <div
                    className="w-100 d-flex justify-content-center"
                    style={{
                      fontSize: deviceType === "Mobile" ? 8 : 16,
                      color: "black",
                    }}
                  >
                    HOURS
                  </div>
                </div>
                <div
                  className="mx-2"
                  style={{
                    width: deviceType === "Mobile" ? 30 : 90,
                    height: deviceType === "Mobile" ? 30 : 90,
                    borderWidth: 2,
                    borderColor: "#f68a20",
                    borderRadius: 10,
                  }}
                >
                  <h1
                    className="w-100 d-flex justify-content-center align-items-center"
                    style={{
                      fontSize: deviceType === "Mobile" ? 12 : null,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {countDown?.data?.minutes}
                  </h1>
                  <div
                    className="w-100 d-flex justify-content-center"
                    style={{
                      fontSize: deviceType === "Mobile" ? 8 : 16,
                      fontSize: 16,
                      color: "black",
                    }}
                  >
                    MINUTES
                  </div>
                </div>
                <div
                  className="mx-2"
                  style={{
                    width: deviceType === "Mobile" ? 30 : 90,
                    height: deviceType === "Mobile" ? 30 : 90,
                    borderWidth: 2,
                    borderColor: "#f68a20",
                    borderRadius: 10,
                  }}
                >
                  <h1
                    className="w-100 d-flex justify-content-center align-items-center"
                    style={{
                      fontSize: deviceType === "Mobile" ? 12 : null,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {countDown?.data?.seconds}
                  </h1>
                  <div
                    className="w-100 d-flex justify-content-center"
                    style={{
                      fontSize: deviceType === "Mobile" ? 8 : 16,
                      fontSize: 16,
                      color: "black",
                    }}
                  >
                    SECONDS
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default About;
