import React from "react";
// import {Button } from "reactstrap";

function ButtonGradient({
  Title,
  leftColor,
  rightColor,
  ColorText,
  BorderColor,
  borderRadius,
  handleOnclick,
}) {
  return (
    <buton
      className="btn"
      style={{
        fontWeight: "600",
        color: ColorText,
        borderColor: BorderColor,
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: borderRadius,
        background: `linear-gradient(to right, ${leftColor}, ${rightColor})`,
      }}
      onClick={handleOnclick}
    >
      {Title}
    </buton>
  );
}

export default ButtonGradient;
