import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../../../Toast/Toast";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

const FormSMS = ({ setIsSMSOpen, userId, eventID, eventDetails, token }) => {
  const [inputs, setInputs] = useState({});
  const [userRole, setUserRole] = useState("all");
  const [btnLoad, setBtnLoad] = useState(false);
  const [warning, setWarning] = useState(""); // New state variable to track warning message
  const queryClient = useQueryClient();
  const key = ["allSMS", eventID];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    setWarning(""); // Clear warning message on input change
  };

  const allParticipantsFunc = (e) => {
    setUserRole("all");
  };
  const attendeesFunc = (e) => {
    setUserRole("attendees");
  };
  const speakersFunc = (e) => {
    setUserRole("speakers");
  };
  const teamMembersFunc = (e) => {
    setUserRole("security");
  };

  const handleSend = (e) => {
    e.preventDefault();

    // Check if the form is valid
    if (!inputs.message) {
      setWarning(
        "Please type a message before submitting. The message field cannot be empty."
      );
      return; // Prevent form submission if not valid
    }

    setBtnLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    const raw = JSON.stringify({
      body: inputs?.message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API}communication/sms/${eventID}?audience=${userRole}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setIsSMSOpen(false);
        setBtnLoad(false);
        if (res?.status === "success") {
          queryClient.invalidateQueries(key);
          successMessage("SMS successfully sent");
        } else {
          warningMessage("No phone number found");
        }
      })
      .catch((error) => {
        setIsSMSOpen(false);
        setBtnLoad(false);
        dangerMessage("No phone number found");
        console.error(error);
      });
  };

  return (
    <div>
      <form onSubmit={handleSend} noValidate>
        <label className="event__input__form__Creation">
          Message<span className="text-danger">*</span>
          <textarea
            name="message"
            value={inputs.message}
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mt-1 form-control fromInput"
            rows={5}
            required
          ></textarea>
        </label>
        {warning && <p className="text-danger mt-2">{warning}</p>}{" "}
        {/* Display warning message if exists */}
        <div className="mt-2 mb-3">
          <label>
            Select your audience<span className="text-danger">*</span>
          </label>
          {/* Radio buttons for audience selection */}
          <div className="form-check mt-2 mb-2">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleAdmin"
              value="allParticipants"
              onChange={allParticipantsFunc}
              defaultChecked
            />
            <label className="form-check-label" htmlFor="exampleAdmin">
              All Participants
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleManager"
              value={"Attendees"}
              onChange={attendeesFunc}
            />
            <label className="form-check-label" htmlFor="exampleManager">
              Attendees
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleEvent"
              value={"Speakers"}
              onChange={speakersFunc}
            />
            <label className="form-check-label" htmlFor="exampleEvent">
              Speakers
            </label>
          </div>
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="radio"
              name="formatRadio"
              id="exampleMember"
              value={"teamMembers"}
              onChange={teamMembersFunc}
            />
            <label className="form-check-label" htmlFor="exampleMember">
              Event Security
            </label>
          </div>
        </div>
        <div className="w-100 mt-2 d-flex justify-content-center">
          <ButtonSubmit
            Title="Send SMS"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={handleSend}
            pending={btnLoad}
          />
        </div>
      </form>
    </div>
  );
};

export default FormSMS;
