import React from "react";
// import PropTypes from "prop-types";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ShowEvents from "./components/ShowEvents";

const Dashboard = () => {
  document.title = "Kago Events | My events";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userRole = obj?.data?.data?.roles;
  const userId = obj?.data?.data?.id;
  const userEmail = obj?.data?.data?.email;
  const token = obj?.data?.token;
  const { t } = useTranslation();
  // console.log("....token...", userEmail);
  // console.log("obj...", obj);
  // console.log("userRole...", userRole);
  return (
    <React.Fragment>
      <div className="page-content" style={{ userSelect: "none" }}>
        <Container>
          <ShowEvents
            t={t}
            userId={userId}
            userRole={userRole}
            userEmail={userEmail}
            token={token}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
