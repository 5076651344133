import React from "react";
import { useTranslation } from "react-i18next";
import useCustomQuery from "../../Hooks/useCustomQuery";
import AnimationContact from "../../components/Loader/Contacts";
import TableContacts from "../../components/Contacts/TableContacts";

// import Breadcrumbs from "../../components/Common/Breadcrumb";

function Contacts() {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userID = obj?.data?.data?._id;
  const token = obj?.data?.token;
  const { t } = useTranslation();

  const {
    data: getMyContacts,
    error: errorGetMyContacts,
    isLoading: isLoadingGetMyContacts,
  } = useCustomQuery(
    ["MyContacts", userID],
    `${process.env.REACT_APP_API}contacts/my-contacts/?user=${userID}`,
    token
  );

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Breadcrumbs title="Kago Events" breadcrumbItem={t("My Contacts")} /> */}
        {getMyContacts?.status === "ok" && getMyContacts?.data?.length === 0 ? (
          <div className="w-100 d-flex justify-content-center">
            <AnimationContact title="You have no contact at the moment" />
          </div>
        ) : null}
        {getMyContacts?.status === "ok" && getMyContacts?.data?.length > 0 ? (
          <div className="w-100 d-flex justify-content-center">
            <TableContacts
              myContacts={getMyContacts?.data}
              userID={userID}
              token={token}
            />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default Contacts;
