import React from "react";

import { Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { MdOutlineSettings } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { FiFileText } from "react-icons/fi";
import { BsChatLeftText } from "react-icons/bs";
import { MdEqualizer } from "react-icons/md";

import classnames from "classnames";

export const Sidebar = ({
  getEventDetails,
  selectActiveTab,
  setSelectActiveTab,
}) => {
  const toggleSelectActiveTab = (tab) => {
    if (selectActiveTab !== tab) {
      setSelectActiveTab(tab);
    }
  };

  const openChat = () => {
    const newTab = window.open(
      "https://tawk.to/chat/65fd674a1ec1082f04da1ddc/1hpitmvma",
      "_blank",
      "noopener,noreferrer"
    );
    if (newTab) newTab.opener = null;
  };
  const openDoc = () => {
    const newTab = window.open(
      "https://kago.digital/",
      "_blank",
      "noopener,noreferrer"
    );
    if (newTab) newTab.opener = null;
  };

  return (
    <div
      style={{
        paddingLeft: 17,
        paddingTop: 80,
      }}
    >
      <Nav pills className="nav flex-column nav-pills text-center">
        {/* <NavItem>
                  <NavLink
                    style={{ cursor: "pointer", width: 200 }}
                    className={classnames({
                      "mb-2": true,
                      active: selectActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1");
                    }}
                  >
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <i className="dripicons-home font-size-18 d-block my-1"></i>
                      </div>
                      <div className="d-flex align-items-center mx-3">
                        Overview
                      </div>
                    </div>
                  </NavLink>
                </NavItem> */}
        <NavItem>
          <NavLink
            style={{
              cursor: "pointer",
              width: "100%",
            }}
            className={classnames({
              "mb-2 event-sidebar-text-color": true,
              active: selectActiveTab === "1",
            })}
            onClick={() => {
              toggleSelectActiveTab("1");
            }}
          >
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <MdOutlineSettings size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">Event Setup</div>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "2",
            })}
            onClick={() => {
              toggleSelectActiveTab("2");
            }}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                <MdModeEdit size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">
                Event Branding
              </div>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "3",
            })}
            onClick={() => {
              toggleSelectActiveTab("3");
            }}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                <FaEdit size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">Registration</div>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "4",
            })}
            onClick={() => {
              toggleSelectActiveTab("4");
            }}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                <i className="dripicons-user-group font-size-18 d-block my-1 mt-1"></i>
              </div>
              <div className="d-flex align-items-center mx-3">Participants</div>
            </div>
          </NavLink>
        </NavItem>
        {getEventDetails?.data?.eventType?.includes("Conference") ? (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer", width: "100%" }}
              className={classnames({
                "mb-2": true,
                active: selectActiveTab === "5",
              })}
              onClick={() => {
                toggleSelectActiveTab("5");
              }}
            >
              <div className="d-flex event-sidebar-text-color">
                <div className="d-flex align-items-center">
                  <i className="dripicons-inbox font-size-18 d-block my-1 mt-1"></i>
                </div>
                <div className="d-flex align-items-center mx-3">Sessions</div>
              </div>
            </NavLink>
          </NavItem>
        ) : null}
        {getEventDetails?.data?.eventType?.includes("Expo") ? (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer", width: "100%" }}
              className={classnames({
                "mb-2": true,
                active: selectActiveTab === "5",
              })}
              onClick={() => {
                toggleSelectActiveTab("5");
              }}
            >
              <div className="d-flex event-sidebar-text-color">
                <div className="d-flex align-items-center">
                  <i className="dripicons-inbox font-size-18 d-block my-1 mt-1"></i>
                </div>
                <div className="d-flex align-items-center mx-3">Sessions</div>
              </div>
            </NavLink>
          </NavItem>
        ) : null}
        {getEventDetails?.data?.eventType?.includes("Exhibition") ? (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer", width: "100%" }}
              className={classnames({
                "mb-2": true,
                active: selectActiveTab === "6",
              })}
              onClick={() => {
                toggleSelectActiveTab("6");
              }}
            >
              <div className="d-flex event-sidebar-text-color">
                <div className="d-flex align-items-center">
                  <i className="dripicons-store font-size-18 d-block my-1 mt-1"></i>
                </div>
                <div className="d-flex align-items-center mx-3">Exhibition</div>
              </div>
            </NavLink>
          </NavItem>
        ) : null}

        {/* {getEventDetails?.data?.eventType?.includes("Exhibition") ? (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer", width: "100%" }}
              className={classnames({
                "mb-2": true,
                active: selectActiveTab === "5",
              })}
              onClick={() => {
                toggleSelectActiveTab("5");
              }}
            >
              <div className="d-flex event-sidebar-text-color">
                <div className="d-flex align-items-center">
                  <i className="dripicons-inbox font-size-18 d-block my-1 mt-1"></i>
                </div>
                <div className="d-flex align-items-center mx-3">Sessions</div>
              </div>
            </NavLink>
          </NavItem>
        ) : null} */}

        {getEventDetails?.data?.eventType?.includes("Exhibition") ? (
          <NavItem>
            <NavLink
              style={{ cursor: "pointer", width: "100%" }}
              className={classnames({
                "mb-2": true,
                active: selectActiveTab === "6",
              })}
              onClick={() => {
                toggleSelectActiveTab("6");
              }}
            >
              <div className="d-flex event-sidebar-text-color">
                <div className="d-flex align-items-center">
                  <i className="dripicons-store font-size-18 d-block my-1 mt-1"></i>
                </div>
                <div className="d-flex align-items-center mx-3">Exhibition</div>
              </div>
            </NavLink>
          </NavItem>
        ) : null}

        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "7",
            })}
            onClick={() => {
              toggleSelectActiveTab("7");
            }}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                <HiOutlineSpeakerphone size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">Marketing</div>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "8",
            })}
            onClick={() => {
              toggleSelectActiveTab("8");
            }}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                {/* <i className=" dripicons-toggles font-size-18 d-block my-1 mt-1"></i> */}
                <MdEqualizer size={22} />
              </div>
              <div className="d-flex align-items-center mx-3">Analytics</div>
            </div>
          </NavLink>
        </NavItem>

        {/* <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "9",
            })}
            onClick={() => {
              toggleSelectActiveTab("9");
            }}
          >
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <FaRegCircleQuestion size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">FAQ's</div>
            </div>
          </NavLink>
        </NavItem> */}

        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "10",
            })}
            onClick={openDoc}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                <FiFileText size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">
                Visit Help Doc
              </div>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "11",
            })}
            onClick={() => {
              openChat();
              // toggleSelectActiveTab("11");
            }}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                <BsChatLeftText size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">
                Chat with Expert
              </div>
            </div>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};
