import React, { useEffect, useState } from "react";
import "./OtpInput.scss";

const OtpInput = ({ length, otp, onChange, onComplete, setTimes }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (otp.join("").length === length) {
      // console.log("....settings...here...", 1);
      onComplete();
    }
  }, [otp, length, onComplete]);

  setTimes(1);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      onChange(newOtp);
      if (index < length - 1) {
        setActiveIndex(index + 1);
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      onChange(newOtp);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index]) {
        const newOtp = [...otp];
        newOtp[index] = "";
        onChange(newOtp);
      } else if (index > 0) {
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        onChange(newOtp);
        setActiveIndex(index - 1);
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      setActiveIndex(index - 1);
      document.getElementById(`otp-input-${index - 1}`).focus();
    } else if (e.key === "ArrowRight" && index < length - 1) {
      setActiveIndex(index + 1);
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  return (
    <div className="otp-input-container">
      {Array?.from({ length })?.map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength="1"
          id={`otp-input-${i}`}
          className={`otp-input text-dark ${
            activeIndex === i ? "active" : "text-dark"
          }`}
          value={otp[i]}
          onChange={(e) => handleInputChange(e, i)}
          onFocus={() => setActiveIndex(i)}
          onKeyDown={(e) => handleKeyDown(e, i)}
        />
      ))}
    </div>
  );
};

export default OtpInput;
