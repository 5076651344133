import React, { useState, useEffect } from "react";
// import { useQueryClient } from "@tanstack/react-query";
// import useCustomFetchQuery from "../../../../Hooks/useCustomFecthQuery";
// import useCustomMutation from "../../../../Hooks/useCustomMutation";
// import { Spinner, Row, Col } from "reactstrap";
// import formatDate from "../../../../utils/formatDate";
// import Profile from "../../../Common/JoinBooth/components/Profile";
import { ReactBarcode } from "react-jsbarcode";
import readDate from "../../../../utils/readDate";
// import userImg from "../../../../assets/images/users/User.png";

import "./viewTicket.scss";

const ViewTicket = ({ details, setIsOffcanvasOpen }) => {
  //   const queryClient = useQueryClient();

  // const [inputs, setInputs] = useState({});
  // const [isEarlyBird, setIsEarlyBird] = useState(false);
  // const key = ["setTicket", eventID];
  // const mutation = useCustomMutation(key);

  // const {
  //   data: getMyTicketSettings,
  //   error: errorGetMyTicketSettings,
  //   isFetching: isFetchingGetMyTicketSettings,
  // } = useCustomFetchQuery(
  //   ["setTicket", eventID],
  //   `${process.env.REACT_APP_API}ticket-settings/${eventID}/event`,
  //   token,
  //   { enabled: !!eventID }
  // );

  // console.log("getMyTicketSettings.....", getMyTicketSettings);

  // const handleSubmitForm = (event) => {
  //   event.preventDefault();
  //   const Method = "POST",
  //     url = `ticket-settings?eid=${eventID}`,
  //     isJSON = null;
  //   const raw = JSON.stringify({
  //     tier: inputs.tier,
  //     price: parseInt(inputs.price),
  //     quantity: parseInt(inputs.quantity),
  //     discount: parseInt(inputs.discount),
  //     allowEarlyBird: isEarlyBird,
  //     earlyBirdStartDate: formatDate(inputs.earlyBirdStartDate),
  //     earlyBirdEndDate: formatDate(inputs.earlyBirdEndDate),
  //     closingDate: formatDate(inputs.closingDate),
  //     ticketTerms: inputs.ticketTerms,
  //   });

  //   const data = {
  //     endPoint: url,
  //     bodyContent: raw,
  //     Method: Method,
  //     displayMessage: "Ticket Successfully Created",
  //     token: token,
  //     isJSON: isJSON,
  //   };
  //   mutation.mutate(data);
  // };

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  // useEffect(() => {
  //   if (mutation?.isSuccess) {
  //     setIsOffcanvasOpen(false);
  //     // queryClient.invalidateQueries(["get-TicketSettings", eventID]);
  //     // handleTicket();
  //   }
  // }, [mutation?.isSuccess]);

  // console.log("details....", details);

  const startDate = readDate(details?.createdAt);
  const endDate = readDate(details?.closingDate);
  const startEarlyBirdDate = readDate(details?.earlyBirdStartDate);
  const endEarlyBirdDate = readDate(details?.earlyBirdEndDate);

  return (
    <div className="w-100">
      <div className="ticket">
        <div className="ticket--start">
          <div className="ticket--right--container"></div>
        </div>
        <div className="ticket--center">
          <div className="ticket--center--row">
            <div className="ticket--center--col">
              <span>Ticket</span>
              <strong>{details?.tier}</strong>
            </div>
            <div className="ticket--center--col">
              <span>Price</span>
              <strong>R {details?.price}</strong>
            </div>
          </div>
          <div className="ticket--center--row">
            <div className="ticket--center--col">
              <span className="ticket--info--title">Start Date</span>
              <span className="ticket--info--subtitle">{startDate}</span>
              {/* <span className="ticket--info--content">
                7:00 am to 9:00 pm (GMT+1)
              </span> */}
            </div>
            <div className="ticket--center--col">
              <span className="ticket--info--title">Closing Date</span>
              <span className="ticket--info--subtitle">{endDate}</span>
              {/* <span className="ticket--info--content">
                Location complete address, Town, COUNTRY
              </span> */}
            </div>
          </div>
          <div className="ticket--center--row">
            <div className="ticket--center--col">
              <span className="ticket--info--title">Sold Ticket(s)</span>
              <span className="ticket--info--content">
                {details?.quantitySold} /{details?.quantity}
              </span>
            </div>
            <div className="ticket--center--col">
              <span className="ticket--info--title">Early Bird discount</span>
              <div className="ticket--info--content">
                <div>{details?.discount ? details?.discount : 0}%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="ticket--end">
          <div>
            {details?.allowEarlyBird ? (
              <>
                <div className="ticket--info--earlyBird--title">
                  Early Bird Start Date
                </div>
                <div className="ticket--info--title" style={{ color: "white" }}>
                  {startEarlyBirdDate}
                </div>
                <div className="ticket--info--earlyBird--title">
                  Early Bird End Date
                </div>
                <div className="ticket--info--title" style={{ color: "white" }}>
                  {endEarlyBirdDate}
                </div>
                <div className="ticket--info--earlyBird--title">
                  Early Bird Price
                </div>
                <div className="ticket--info--title" style={{ color: "white" }}>
                  R {details?.tierEarlyBirdPrice}
                </div>
              </>
            ) : null}

            <div className=" mt-2 ticket--info--earlyBird--title">
              Last Update
            </div>
            <div className="ticket--info--title" style={{ color: "white" }}>
              {details?.userId?.firstName} {details?.userId?.lastName}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="ticket--info--title" style={{ color: "black" }}>
          Terms Ticket
        </div>
        <div className="ticket--info--content">{details?.ticketTerms}</div>
      </div>
    </div>
  );
};
export default ViewTicket;
