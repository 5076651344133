import React from "react";
import { Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import { FaChevronCircleRight } from "react-icons/fa";
import ImagePlaceHolder from "../../../assets/images/placeholder/ImagePlaceHolder.png";
import communityImg from "../../../assets/images/community/Community.png";
import ButtonSubmit from "../../../components/Buttons/ButtonStyle";
import Nothing from "../../../components/Loader/Contacts";

function AllEventsDevice({ search, userId, userEmail, token, t, isSwitchOn }) {
  const Navigate = useNavigate();

  const {
    data: getEvents,
    error: errorGetEvents,
    isLoading: isLoadingGetEvents,
  } = useCustomQuery(
    ["MyEvents", userEmail],
    `${process.env.REACT_APP_API}events/personnel?email=${userEmail}`,
    token
  );

  const {
    data: getAttendesEvents,
    error: errorGetAttendesEvents,
    isLoading: isLoadingGetAttendesEvents,
  } = useCustomQuery(
    ["MyAttendeeEvents", userEmail],
    `${process.env.REACT_APP_API}events/attendee-events?email=${userEmail}`,
    token
  );

  //  `${process.env.REACT_APP_API}communities/?oid=${userId}`,

  // console.log("....community.....", getCommunities);
  // console.log("....token.....", token);

  // console.log("....getEvents.....", getEvents);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleAttendeeEnterEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
  };

  const myEventsSearchResult = () => {
    return (
      <>
        {getEvents?.data?.map((item, index) => {
          // const date = item.StartDateTime.split(" ");
          // console.log("Get all events....", item?.state);
          // console.log("Get all events....", item);

          // Event date configuration
          let dateValue = new Date(item.startDate);

          let day = dayNames[dateValue.getUTCDay()];
          let month = monthNames[dateValue.getUTCMonth()];
          let year = dateValue.getUTCFullYear();
          let date = dateValue.getUTCDate();

          let formattedDate = `${day} ${date} ${month} ${year}`;
          // console.log("item..........", item);
          // if (item?.state === "Published") {
          return (
            <>
              <Col xs={12} sm={12} md={12} lg={12} key={index}>
                <div className="p-3 mb-4 shadow-sm bg-white card-brd">
                  <div className="d-flex align-items-center" style={{}}>
                    <div className="">
                      <img
                        src={
                          item?.bannerImage
                            ? item?.bannerImage
                            : ImagePlaceHolder
                        }
                        width="100%"
                        height="100%"
                        className="card-brd"
                        alt="event"
                      />
                    </div>
                  </div>
                  <div className="w-100 mx-2">
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      {item?.eventName}
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: 13,
                      }}
                    >
                      {item?.eventDescription &&
                      item?.eventDescription.length > 35
                        ? `${item?.eventDescription?.substring(0, 200)}...`
                        : null}
                      {item?.eventDescription &&
                      item?.eventDescription.length <= 35
                        ? item?.eventDescription
                        : null}
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      {formattedDate} |{" "}
                      {`${item.startTime} - ${item.endTime} SAST`}
                    </div>

                    <div
                      className="mt-2"
                      style={{
                        fontSize: 13,
                      }}
                    >
                      <ButtonSubmit
                        Title="Visit Event"
                        ColorText="#F68A20"
                        BorderColor="#F68A20"
                        BackgroundColor="white"
                        borderRadius="25px"
                        handleOnclick={() =>
                          handleAttendeeEnterEvent(item?.eventName, item?._id)
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </>
          );
          // }
        })}
      </>
    );
  };

  return (
    <>
      {getEvents?.data?.length === 0 &&
      getEvents?.status === "ok" &&
      getAttendesEvents?.data?.length === 0 &&
      getAttendesEvents?.status === "ok" ? (
        <div className="h1">
          <Nothing title="You have no event at the moment" />
        </div>
      ) : (
        <>{myEventsSearchResult()}</>
      )}
    </>
  );
}

export default AllEventsDevice;
