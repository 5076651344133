import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import { FaSave, FaPlus } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";

const View = ({ formContent }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [textField, setTextField] = useState("");
  const [editedField, setEditedField] = useState("");

  const toggleSaveChanges = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  console.log("formContent...", formContent);
  //   console.log("formContent[0].label...", formContent[0]);

  return (
    <div className=" w-100 mt-2 rounded-3" style={{ backgroundColor: "white" }}>
      <div className="w-100 d-flex justify-content-center align-items-center mt-2 ">
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ width: "64%", fontSize: 18, fontWeight: "600" }}
        >
          {/* Create Registration Form */}
        </div>

        {/* <div
          className="d-flex justify-content-end align-items-center px-4 py-2"
          style={{ width: "36%" }}
        >
          <ButtonIcon
            Title="Save"
            BackgroundColor="#f68a20"
            ColorText="#fff"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={toggleSaveChanges}
            Icon={<FaSave color="white" />}
            pending={false}
          />
        </div> */}
      </div>
      <div className="w-100  mb-2">
        <Row>
          <Col md={12}>
            <div className="w-100">
              <label className="text-dark">{formContent[0].label}</label>
              <input
                type="text"
                value={formContent[0].label}
                onBlur={() => {
                  setOnEdit(false);
                  setEditedField("");
                }}
                className="form-control"
                disabled
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="w-100  mb-2">
              <div className="w-100">
                <label className="text-dark">{formContent[1].label}</label>
                <input
                  type="text"
                  value={formContent[1].label}
                  onBlur={() => {
                    setOnEdit(false);
                    setEditedField("");
                  }}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="w-100  mb-2">
              <div className="w-100">
                <label className="text-dark">{formContent[2].label}</label>
                <input
                  type="text"
                  value={formContent[2].label}
                  onBlur={() => {
                    setOnEdit(false);
                    setEditedField("");
                  }}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="w-100 mb-2">
              <div className="w-100  mb-2">
                <div className="w-100">
                  <label className="text-dark">{formContent[3].label}</label>
                  <input
                    type="text"
                    value={formContent[3].label}
                    onBlur={() => {
                      setOnEdit(false);
                      setEditedField("");
                    }}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="w-100 px-4">
        {formContent?.slice(4)?.map((field) => {
          return (
            <div>
              <div className="flex justify-content-between align-items-center">
                <div key={field.name}>
                  {onEdit && editedField === field.name ? (
                    <input
                      type="text"
                      value={field.label}
                      onBlur={() => {
                        setOnEdit(false);
                        setEditedField("");
                      }}
                      className="form-control"
                    />
                  ) : (
                    <label
                      onClick={() => {
                        setOnEdit(true);
                        setEditedField(field.name);
                      }}
                    >
                      {field.label}
                    </label>
                  )}
                </div>
              </div>

              <div className="my-4">
                {field.question_type === "text" && (
                  <input
                    type="text"
                    className="w-100 form-control"
                    placeholder={field.label}
                  />
                )}
                {field.question_type === "short_answer" && (
                  <input
                    type="text"
                    className="w-100 form-control"
                    placeholder={field.label}
                  />
                )}
                {field.question_type === "paragraph" && (
                  <textarea
                    rows={5}
                    className="form-control"
                    placeholder={field.label}
                  />
                )}
                {field.question_type === "options" && (
                  <div className="w-100 my-4">
                    <div className="w-100 mt-3">
                      <div>{field.label}</div>
                      <select className="px-5 form-control">
                        <option>Select...</option>
                        {field.list.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="w-100 d-flex justify-content-center align-items-center p-2 my-3">
        <ButtonIcon
          Title="Add Field"
          BackgroundColor="#000"
          ColorText="#fff"
          BorderColor="#000"
          borderRadius={20}
          handleOnclick={addQuestion}
          Icon={<FaPlus color="white" />}
        />
      </div> */}
    </div>
  );
};

export default View;
