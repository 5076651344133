import React from "react";
import { useParams } from "react-router-dom";
import Main from "./components/Main";

// import Navigation from "./Components/Navigation";

const Index = () => {
  document.title = "Kago Events | Live Session";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.data?.token;
  const params = useParams();
  const eventID = params.eventID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");

  // console.log("myEventName...", myEventName);

  return (
    <React.Fragment>
      <Main
        token={token}
        userId={userId}
        eventID={eventID}
        eventName={myEventName}
      />
    </React.Fragment>
  );
};

export default Index;
