import React, { useState, useEffect } from "react";
import { useNavigate, Link,useParams } from "react-router-dom";
import usePost from "../../Hooks/usePost";
import { Row } from "reactstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Logo from "../../components/Authentication/Logo";
import ButtonSubmit from "../Buttons/ButtonSubmit";

export default function SignUpForm() {
  let navigate = useNavigate();  
    const params = useParams();
   const Id = params.token;
  const { execute, pending, data, status } = usePost();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const passwordsMatch = values.password === values.confirmPassword;

  const handleReset = () => {
    setIsButtonClicked(true); // Update the state when the button is clicked
    if (passwordsMatch) {
      const Method = "PATCH",
        endPoint = `auth/resetPassword/${Id}`,
        token = null;
      const raw = JSON.stringify({
        password: values.password,
        passwordConfirm: values.confirmPassword,
      });
      execute(endPoint, raw, Method, "Successfully password reset", token);
    }
  };

  useEffect(() => {
    if (status == "success") {
      navigate("/login");
    }
  }, [status]);

  return (
    <div className="login-form-container">
      <div className="login-form-header">
        <Logo />
        <h1>Reset Password</h1>
        <p>Reset your account password</p>
      </div>
      <Row>
        <div className="form-field">
          <label>Password</label>
          <div className="password-input">
            <input
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              style={{ borderRadius: "10px" }}
              name="password"
            />
            <button
              className="eye-icon"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <FiEye /> : <FiEyeOff />}
            </button>
          </div>
        </div>
        <div className="form-field">
          <label>Confirm Password</label>
          <div className="password-input">
            <input
              type={values.showPassword ? "text" : "password"}
              value={values.confirmPassword}
              onChange={handleChange("confirmPassword")}
              name="confirmPassword"
            />
            <button
              className="eye-icon"
              onClick={handleClickShowConfirmPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showConfirmPassword ? <FiEye /> : <FiEyeOff />}
            </button>
          </div>
          {isButtonClicked && !passwordsMatch && (
            <p className="mt-1 text-danger">Passwords do not match</p>
          )}
        </div>
      </Row>
      <div className="w-100 mb-2 d-flex justify-content-end">
        <div className="mx-1">Already have an account?</div>
        <Link to="/login">Login</Link>
      </div>
      <div className="d-flex justify-content-center">
        <ButtonSubmit
          Title="Confirm"
          BackgroundColor="#f68a20"
          ColorText="#fff"
          BorderColor="#f68a20"
          borderRadius={25}
          handleOnclick={handleReset}
          pending={pending}
        />
      </div>
    </div>
  );
}
