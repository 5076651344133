import React from "react";
import CreateRegistration from "./CreateRegistration";

const RegistrationTab = ({ userID, token, eventID }) => {
  return (
    <React.Fragment>
      <div className="w-100 d-flex justify-content-center">
        <CreateRegistration />
      </div>
    </React.Fragment>
  );
};

export default RegistrationTab;
