import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Spinner, Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useQueryClient } from "@tanstack/react-query";
import usePost from "../../../../Hooks/usePost";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import ImgSession from "../../../../assets/images/banners/sessions/bannerSession.png";
import { BiEdit } from "react-icons/bi";

import "./css/Summary.scss";
import "./css/AddSponsor.scss";

const AddCompanyProfile = ({
  userId,
  token,
  boothID,
  eventID,
  setSlideInOpenCompany,
  reFetchDetails,
}) => {
  const queryClient = useQueryClient();
  const { execute, pending, data } = usePost();
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.email;
  const key = ["exhibtitor-CompanyBooth", eventID];
  const mutation = useCustomMutation(key);
  const [logoServer, setLogoServer] = useState();
  const [profile, setProfile] = useState();
  const [inputs, setInputs] = useState({});

  // const {
  //   data: getMyBoothDetails,
  //   error: errorGetMyBoothDetails,
  //   isLoading: isLoadingGetMyBoothDetailsl,
  // } = useCustomQuery(
  //   ["myBoothDetails", eventID],
  //   `${process.env.REACT_APP_API}booths/?email=${userEmail}`,
  //   token
  // );

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogoServer(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // if (isLoadingGetMyBoothDetailsl) {
  //   <div className="mt-5 d-flex justify-content-center align-items-center">
  //     <Spinner animation="border" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </Spinner>
  //   </div>;
  // }
  // console.log("eventId...", eventID);
  // console.log("boothID.....", boothID);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "POST",
      url = `company-profile/${boothID}`,
      isJSON = true;
    const formdata = new FormData();

    formdata.append("companyLogo", logoServer);
    formdata.append("companyName", inputs.companyName);
    formdata.append("companyDescription", inputs.companyDescription);
    formdata.append("companyAddress", inputs.companyAddress);
    formdata.append("companyPhone", inputs.companyPhoneNumber);
    formdata.append("companyFax", inputs.companyFax);
    formdata.append("companyWebsite", inputs.companyWebsite);
    formdata.append("companyFacebook", inputs.companyfacebook);
    formdata.append("companyInstagram", inputs.companyinstagram);
    // formdata.append("companyLinkedIn", inputs.companylinkedIn);
    // execute(
    //   url,
    //   formdata,
    //   Method,
    //   "Company Profile added successfully",
    //   token,
    //   isJSON
    // );
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Company Profile added successfully",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      console.log("....isSuccess...", mutation.isSuccess);
      setSlideInOpenCompany(false);
      // queryClient.invalidateQueries(["myBooth-Company", eventID]);
      // queryClient.invalidateQueries(["myExbCompany", eventID]);
    }
    // if (data?.status === "success") {
    //   Navigate("/login");
    // }
  }, [mutation.isSuccess]);

  // if (mutation.isSuccess) {
  //   reFetchDetails();
  //   queryClient.invalidateQueries(["myBooth-Company", eventID]);
  //   setSlideInOpenCompany(false);
  // }

  return (
    <React.Fragment>
      <div className="sponsor-form-container">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="sponsor-form-body"
          onSubmit={handleSubmitForm}
        >
          <div className="sponsor-form-body__sponsor-logo">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={!logoServer ? ImgSession : profile}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-55px" }}
            >
              <p
                color="ambience.6"
                fontSize="1"
                className="sponsor-form-body--input-description"
              >
                <div className="text-dark"></div>
                Recommended format: 250x250px | JPG, PNG | Up to 3 MB
              </p>
            </div>
          </div>
          <Row>
            <Col md={12}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyName">
                  <p className="sponsor-form-body--input-heading">
                    Company Name*
                  </p>
                </label>
                <input
                  type="text"
                  id="companyName"
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyName"
                  aria-label="companyName"
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyDescription">
                  <p className="sponsor-form-body--input-heading">
                    Company Description
                  </p>
                </label>
                {/* <textarea
                  name="companyDescription"
                  onChange={(e) => handleChange(e)}
                  placeholder="write..."
                  className="w-100 mb-3 sponsor-form-body__input--field"
                  rows={5}
                  required
                ></textarea> */}
                <input
                  type="text"
                  id="companyDescription"
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyDescription"
                  aria-label="companyDescription"
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyAddress">
                  <p className="sponsor-form-body--input-heading">
                    Company Address
                  </p>
                </label>
                <input
                  type="text"
                  id="companyAddress"
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyAddress"
                  aria-label="companyDescription"
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyPhoneNumber">
                  <p className="sponsor-form-body--input-heading">
                    Company Phone number
                  </p>
                </label>
                <input
                  type="text"
                  id="companyPhoneNumber"
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyPhoneNumber"
                  aria-label="companyDescription"
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyFax">
                  <p className="sponsor-form-body--input-heading">
                    Company Fax number
                  </p>
                </label>
                <input
                  type="text"
                  id="companyFax"
                  placeholder=""
                  className="sponsor-form-body__input--field"
                  name="companyFax"
                  aria-label="companyDescription"
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyWebsite">
                  <p className="sponsor-form-body--input-heading">
                    Company Website
                  </p>
                </label>
                <input
                  type="text"
                  id="companyWebsite"
                  placeholder="https://www.yourCompany.com/"
                  className="sponsor-form-body__input--field"
                  name="companyWebsite"
                  aria-label="companyDescription"
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyfacebook">
                  <p className="sponsor-form-body--input-heading">
                    Company facebook Link
                  </p>
                </label>
                <input
                  type="text"
                  id="companyfacebook"
                  placeholder="https://www.fascebook.com/"
                  className="sponsor-form-body__input--field"
                  name="companyfacebook"
                  aria-label="companyDescription"
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companyinstagram">
                  <p className="sponsor-form-body--input-heading">
                    Company Instagram Link
                  </p>
                </label>
                <input
                  type="text"
                  id="companyinstagram"
                  placeholder="https://www.instragram.com/"
                  className="sponsor-form-body__input--field"
                  name="companyinstagram"
                  aria-label="companyinstagram"
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="sponsor-form-body__input">
                <label htmlFor="companylinkedIn">
                  <p className="sponsor-form-body--input-heading">
                    Company LinkdIn Link
                  </p>
                </label>
                <input
                  type="text"
                  id="companylinkedIn"
                  placeholder="https://www.linkedIn.com/"
                  className="sponsor-form-body__input--field"
                  name="companylinkedIn"
                  aria-label="companylinkedIn"
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <button className="btn-add-sponsor" type="submit">
            <div className="d-flex justify-content-center align-items-center">
              {!mutation.isLoading ? <span>Add Company</span> : null}
              {!mutation.isLoading ? null : (
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading
                </span>
              )}
            </div>
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AddCompanyProfile;
