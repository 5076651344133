import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import usePost from "../../../../Hooks/usePost";
import Divider from "./Divider";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

const RegisterNow = ({ eventID, firstName, lastName, email, phone }) => {
  const [formContent, setFormContent] = useState([]);
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const { status, execute, data, pending } = usePost();
  let navigate = useNavigate();

  const {
    data: getMyEventForm,
    error: errorGetMyEventForm,
    isLoading: isLoadingGetMyEventForm,
  } = useCustomFecthQuery(
    ["eventForm", eventID],
    `${process.env.REACT_APP_API}event-form/${eventID}`,
    null,
    { enabled: !!eventID }
  );

  useEffect(() => {
    // Update state only if prop values are provided
    setInputs((prevInputs) => ({
      ...prevInputs,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(email && { email }),
      ...(phone && { phoneNumber: phone }),
    }));
  }, [firstName, lastName, email, phone]);

  const handleRegister = (e) => {
    e.preventDefault();

    // Validate inputs
    let valid = true;
    const newErrors = {};

    if (!inputs.firstName) {
      newErrors.firstName = "First name is required";
      valid = false;
    }
    if (!inputs.lastName) {
      newErrors.lastName = "Last name is required";
      valid = false;
    }
    if (!inputs.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) {
      return; // Stop submission if there are errors
    }

    const customFields = formContent?.slice(4)?.map((field) => {
      return {
        label: field.label,
        value: inputs[field.name] || "",
      };
    });

    const finalInputs = {
      firstName: inputs.firstName || "",
      lastName: inputs.lastName || "",
      email: inputs.email || "",
      phoneNumber: inputs.phoneNumber || "",
      customField: customFields,
    };

    const Method = "POST",
      endPoint = `attendees/add/${eventID}`;

    const raw = JSON.stringify({
      firstName: finalInputs?.firstName,
      lastName: finalInputs?.lastName,
      email: finalInputs?.email,
      phoneNumber: finalInputs?.phoneNumber,
      customFields: customFields,
    });
    execute(endPoint, raw, Method, "Successfully Registered", null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    setErrors((errors) => ({ ...errors, [name]: "" })); // Clear error when user starts typing
  };

  const handlePhoneChange = (value) => {
    setInputs((inputs) => ({ ...inputs, phoneNumber: value }));
    setErrors((errors) => ({ ...errors, phoneNumber: "" })); // Clear error when user starts typing
  };

  useEffect(() => {
    if (getMyEventForm?.data[0]?._id) {
      setFormContent(getMyEventForm?.data[0]?.customFields);
    }
    if (status === "success") {
      navigate("/login");
    }
  }, [getMyEventForm?.data[0]?._id, status]);

  return (
    <React.Fragment>
      <div className="w-100 mt-2 d-flex justify-content-center">
        <h1
          id="schedule"
          className="d-flex justify-content-center"
          style={{ color: "black", fontWeight: "bold" }}
        >
          RSVP Now
        </h1>
      </div>
      <Divider />
      <div className="w-100 mt-4 mb-3 d-flex justify-content-center">
        <div
          style={{
            width: 650,
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
          }}
        >
          <div className="w-100 px-4 mb-2 mt-2 ">
            <form
              noValidate
              autoComplete="off"
              data-testid="form"
              onSubmit={handleRegister}
            >
              <Row>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      First name<span className="text-danger">*</span>
                    </label>
                    <input
                      name="firstName"
                      type="text"
                      value={inputs.firstName}
                      className="form-control"
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <div className="text-danger">{errors.firstName}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Last name<span className="text-danger">*</span>
                    </label>
                    <input
                      name="lastName"
                      type="text"
                      value={inputs.lastName}
                      className="form-control"
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <div className="text-danger">{errors.lastName}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      type="email"
                      value={inputs.email}
                      className="form-control"
                      disabled
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <div className="phone-input-container">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="ZA"
                        value={inputs.phoneNumber}
                        onChange={handlePhoneChange}
                        className="custom-phone-input"
                      />
                    </div>
                    {errors.phoneNumber && (
                      <div className="text-danger">{errors.phoneNumber}</div>
                    )}
                  </div>
                </Col>
                {formContent?.slice(4)?.map((field) => (
                  <Col md={12} key={field._id}>
                    <div className="">
                      {field.question_type === "text" && (
                        <div className="mb-2">
                          <label>{field?.label}</label>
                          <input
                            type="text"
                            className="w-100 form-control"
                            placeholder={field.label}
                            name={field.name}
                            value={inputs[field.name] || ""}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                      {field.question_type === "short_answer" && (
                        <div className="mb-2">
                          <label>{field?.label}</label>
                          <input
                            type="text"
                            className="w-100 form-control"
                            placeholder={field.label}
                            name={field.name}
                            value={inputs[field.name] || ""}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                      {field.question_type === "paragraph" && (
                        <div className="mb-2">
                          <label>{field?.label}</label>
                          <textarea
                            rows={5}
                            className="form-control"
                            placeholder={field.label}
                            name={field.name}
                            value={inputs[field.name] || ""}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                      {field?.question_type === "options" && (
                        <div className="mb-2">
                          <label>{field?.label}</label>
                          <div className="w-25">
                            <select
                              className="px-2 form-control"
                              name={field.name}
                              value={inputs[field.name] || ""}
                              onChange={handleChange}
                            >
                              <option>Select...</option>
                              {field.list.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>

              <div className="w-100 mt-3 mb-2 d-flex justify-content-center">
                <ButtonSubmit
                  Title="Submit"
                  ColorText="white"
                  BorderColor="#F68A20"
                  BackgroundColor="#F68A20"
                  borderRadius="25px"
                  handleOnclick={handleRegister}
                  pending={pending}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegisterNow;
