import React, { useState } from "react";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import ShowSpeaker from "../../../Profile/ShowSpeaker";

const SpeakerCard = ({ speaker }) => {
  const [isToggleView, setIsToggleView] = useState(false);
  const [speakerDetails, setSpeakerDetails] = useState(null);

  const handleViewSpeaker = (details) => {
    setSpeakerDetails(details);
    setIsToggleView(!isToggleView);
  };

  const tog_Speaker = () => {
    setSpeakerDetails({});
    setIsToggleView(!isToggleView);
  };

  // console.log("speaker....", speaker);

  return (
    <>
      <div className="speaker-container-main-card">
        <div className="speaker-container-main">
          <div className="w-100 d-flex justify-content-center">
            <div className="speaker-image">
              <img src={speaker?.photo} alt={speaker?.firstName} />
            </div>
          </div>

          <div className="mx-2 speaker-img-text"> </div>
          <div className="speaker-img-Title">
            {`${speaker?.title}. `} {speaker?.firstName} {speaker?.lastName}
          </div>

          <div className="speaker-img-Role">{speaker?.jobTitle}</div>
          {speaker?.speakerBiography?.length < 60 ? (
            <div className="speaker-img-description">
              {speaker?.speakerBiography}
            </div>
          ) : (
            <div className="speaker-img-description">
              <div className="d-flex justify-content-center align-items-center">{`${speaker?.speakerBiography.substring(
                0,
                60
              )}...`}</div>

              <div
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#f68a20",
                  cursor: "pointer",
                }}
                onClick={() => handleViewSpeaker(speaker)}
              >
                More
              </div>
            </div>
          )}
        </div>
      </div>
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Speaker"
        body={<ShowSpeaker speaker={speakerDetails} />}
        toggleSaveChanges={() => tog_Speaker()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </>
  );
};

export default SpeakerCard;
