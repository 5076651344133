import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import { isIOS, isTablet, isMobile, isBrowser } from "react-device-detect";
import SwitchButton from "../../../components/Buttons/SwitchButton";
import CreateCommunity from "../../../components/Community/AddCommunity";

import All from "./All";
import AllDevice from "./AllDevice";
import { AiOutlineSearch } from "react-icons/ai";
import { FaCalendarAlt, FaChevronRight, FaBuilding } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const ShowEvents = ({ t, userId, userRole, userEmail, token }) => {
  // const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  // const user = obj?.data?.data;
  const Navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [ViewAll, setViewAll] = useState(true);
  // const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [modal, setModal] = useState(false);

  const handleViewAllClick = (v) => {
    setViewAll(v);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const toggleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleCreateEventBtn = () => {
    Navigate("/create-event");
    // if (userRole?.includes("event_organizer")) {
    //   Navigate("/create-event");
    // } else {
    //   Navigate("/talk-to-us");
    // }
  };

  const handleCreateCommunity = () => {
    setModal(!modal);
  };
  if (isIOS && isMobile && !isTablet) {
    //ios phone
    return (
      <div className="mt-5">
        <Col xs={12} sm={12} md={8} lg={8}>
          <AllDevice t={t} search={search} isSwitchOn={isSwitchOn} />
        </Col>
      </div>
    );
  }
  if (isIOS && isTablet) {
    // ios tablet
    return (
      <div className="mt-5">
        <div className="mt-5">
          <Col xs={12} sm={12} md={8} lg={8}>
            <All t={t} search={search} isSwitchOn={isSwitchOn} />
          </Col>
        </div>
      </div>
    );
  }
  if (isMobile && navigator.userAgent.includes("Build/Huawei")) {
    //huawei phone
    return (
      <div className="mt-5">
        <Col xs={12} sm={12} md={8} lg={8}>
          <AllDevice t={t} search={search} isSwitchOn={isSwitchOn} />
        </Col>
      </div>
    );
  }
  if (isTablet && navigator.userAgent.includes("Build/Huawei")) {
    //huawei tablet
    return (
      <div className="mt-5">
        <Col xs={12} sm={12} md={8} lg={8}>
          <All t={t} search={search} isSwitchOn={isSwitchOn} />
        </Col>
      </div>
    );
  }

  if (isMobile && !navigator.userAgent.includes("Build/Huawei")) {
    //android phone
    return (
      <div className="mt-5">
        <Col xs={12} sm={12} md={8} lg={8}>
          <AllDevice t={t} search={search} isSwitchOn={isSwitchOn} />
        </Col>
      </div>
    );
  }
  if (isTablet && !navigator.userAgent.includes("Build/Huawei")) {
    //android tablet
    return (
      <div className="mt-5">
        <Col xs={12} sm={12} md={8} lg={8}>
          <All t={t} search={search} isSwitchOn={isSwitchOn} />
        </Col>
      </div>
    );
  }

  if (isBrowser) {
    return (
      <div className="w-100">
        <Row>
          <Col xs={12} sm={12} md={4} lg={4}>
            <div className="bg-white p-3 rounded-4">
              <div>
                <p className="align-justify">
                  Here are listed the communities you're part of. When you
                  registered for an event, you become also a member of the
                  Community of this event.
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <div>Display events only</div>
                <div>
                  <SwitchButton
                    isOn={isSwitchOn}
                    handleToggle={() => setIsSwitchOn(!isSwitchOn)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 15,
                  width: "100%",
                  background: "#ffffff",
                  paddingTop: ".58rem",
                  paddingBottom: ".5rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  marginRight: 32,
                  border: "solid",
                  borderWidth: 0.1,
                  borderRadius: 8,
                }}
              >
                <AiOutlineSearch size={24} />
                <input
                  type="text"
                  placeholder={t("Search event name")}
                  value={search}
                  onChange={handleSearch}
                  style={{
                    border: "none",
                    marginLeft: 8,
                    paddingRight: 24,
                  }}
                />
              </div>
              {/* <hr></hr> */}
              {/* //<div
              className="d-flex justify-content-between align-items-center"
              onClick={() => {
                handleCreateEventBtn();
              }}
            >
             <div className="d-flex justify-content-between align-items-center">
                 <div
                  style={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                  }}
                  className="bg-primary"
                >
                  <FaCalendarAlt size={15} color="white" />
                </div>
                <div
                  style={{
                    color: "black",
                    fontSize: 14,
                    fontWeight: "bolder",
                    marginLeft: 12,
                  }}
                >
                  Create Event
                </div>
              </div>  
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaChevronRight size={20} color="#F68A20" />
              </div>
            </div> */}
              {userRole?.includes("event_organizer") && (
                <>
                  {/* <hr></hr>
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => {
                    handleCreateCommunity();
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                      className="bg-primary"
                    >
                      <FaBuilding size={15} color="white" />
                    </div>
                    <div
                      style={{
                        color: "black",
                        fontSize: 14,
                        fontWeight: "bolder",
                        marginLeft: 12,
                      }}
                    >
                      Create New Community
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FaChevronRight size={20} color="#F68A20" />
                  </div>
                </div> */}
                </>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <All t={t} search={search} isSwitchOn={isSwitchOn} />
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={handleCreateCommunity} fullscreen>
          <ModalBody>
            <div className="d-flex justify-content-end">
              <div
                className="d-flex justify-content-center align-items"
                style={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundColor: "#fcceba",
                }}
                onClick={() => handleCreateCommunity()}
              >
                <MdClose size={30} />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <CreateCommunity
                userId={userId}
                userEmail={userEmail}
                token={token}
                setModal={setModal}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};
export default ShowEvents;
