import React from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import "./css/boothSetup.scss";

import Navbar from "./NavBar/NavBar";
import BoothTab from "./components/BoothTab/Index";

function Index({}) {
  document.title = "Kago Events | Booth Set Up";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.email;
  // const userId = obj?.data?.data?.id;
  const token = obj?.data?.token;
  const params = useParams();
  const eventID = params?.eventID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["boothEventDetails", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  const {
    data: getMyBoothInvite,
    error: errorGetMyBoothInvite,
    isLoading: isLoadingGetMyBoothInvite,
  } = useCustomQuery(
    ["myBoothInvite", eventID],
    `${process.env.REACT_APP_API}exhibitors/?email=${userEmail}`,
    token
  );

  const {
    data: getMyBoothDetails,
    error: errorGetMyBoothDetails,
    isLoading: isLoadingGetMyBoothDetailsl,
  } = useCustomQuery(
    ["exhibtitor-BoothDetails", eventID],
    `${process.env.REACT_APP_API}booths/?email=${userEmail}&eventId=${eventID}`,
    token
  );

  const isBoothIdAvailable = !!getMyBoothDetails?.id;

  const {
    data: company,
    error: errorGetMyBoothCompanyProfile,
    isLoading: isLoadingGetMyBoothCompanyProfile,
  } = useCustomFecthQuery(
    ["exhibtitor-CompanyBooth", eventID],
    `${process.env.REACT_APP_API}company-profile/booth/${getMyBoothDetails?.id}`,
    token,
    {
      enabled: isBoothIdAvailable,
    }
  );

  const {
    data: MyBoothSponsponsors,
    error: errorGetMyBoothSponsponsors,
    isLoading: isLoadingGetMyBoothSponsponsors,
  } = useCustomFecthQuery(
    ["exhibtitor-SponsorsBooth", eventID],
    `${process.env.REACT_APP_API}sponsors/booth/${getMyBoothDetails?.id}`,
    token,
    {
      enabled: isBoothIdAvailable,
    }
  );

  const {
    data: MyBoothProducts,
    error: errorGetMyBoothProducts,
    isLoading: isLoadingGetMyBoothProducts,
  } = useCustomFecthQuery(
    ["exhibtitor-ProductsBooth", eventID],
    `${process.env.REACT_APP_API}products/booth/${getMyBoothDetails?.id}`,
    token,
    {
      enabled: isBoothIdAvailable,
    }
  );

  const {
    data: MyBoothLeads,
    error: errorGetMyBoothLeads,
    isLoading: isLoadingGetMyBoothLeads,
  } = useCustomFecthQuery(
    ["exhibtitor-LeadsBooth", eventID],
    `${process.env.REACT_APP_API}booth-leads/booth/${getMyBoothDetails?.id}`,
    token,
    {
      enabled: isBoothIdAvailable,
    }
  );

  // console.log("MyBoothLeads....", MyBoothLeads);

  const boothID =
    getMyBoothDetails && getMyBoothDetails._id ? getMyBoothDetails?.id : null;

  return (
    <div className="page-content" style={{ userSelect: "none" }}>
      <Navbar
        eventID={eventID}
        token={token}
        boothDetails={getMyBoothDetails}
        boothID={boothID}
        boothInvite={getMyBoothInvite}
        myEventName={myEventName}
        eventDetails={getMyEventDetail}
      />
      <BoothTab
        eventID={eventID}
        token={token}
        boothDetails={getMyBoothDetails}
        boothID={boothID}
        boothInvite={getMyBoothInvite}
        eventDetails={getMyEventDetail}
        company={company}
        sponsors={MyBoothSponsponsors}
        products={MyBoothProducts}
        leads={MyBoothLeads}
        // reFetchDetails={reFetchDetails()}
      />
    </div>
  );
}

export default Index;
