import React from "react";
import { Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import { FaChevronCircleRight } from "react-icons/fa";
import ImagePlaceHolder from "../../../assets/images/placeholder/ImagePlaceHolder.png";
import communityImg from "../../../assets/images/community/Community.png";
import ButtonSubmit from "../../../components/Buttons/ButtonStyle";
import Nothing from "../../../components/Loader/Contacts";
function AllEvents({ search, userId, userEmail, token, t, isSwitchOn }) {
  const Navigate = useNavigate();

  const {
    data: getEvents,
    error: errorGetEvents,
    isLoading: isLoadingGetEvents,
  } = useCustomQuery(
    ["MyEvents", userEmail],
    `${process.env.REACT_APP_API}events/personnel?email=${userEmail}`,
    token
  );

  const {
    data: getCommunities,
    error: errorGetCommunities,
    isLoading: isLoadingGetCommunities,
  } = useCustomQuery(
    ["MyCommunities", userEmail],
    `${process.env.REACT_APP_API}communities/user/${userEmail}`,
    token
  );
  //  `${process.env.REACT_APP_API}communities/?oid=${userId}`,

  // console.log("....community.....", getCommunities);
  // console.log("....token.....", token);

  // console.log("....getEvents.....", getEvents);

  const filterEventsByName = (events, searchQuery, isSwitchOnQuery) => {
    // console.log("....events.....8888888", events);
    if (isSwitchOnQuery) {
      return events?.filter((event) =>
        event?.eventName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else {
      if (!searchQuery) {
        const myEvent = [];
        return myEvent;
      } else {
        return events?.filter((event) =>
          event?.eventName?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    }
  };
  const filteredEvents = filterEventsByName(
    getEvents?.data,
    search,
    isSwitchOn
  );

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const handleAttendeeEnterEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
  };
  // Function to check if the email exists in the personnel array
  const checkForEmail = (personnelArray, targetEmail) => {
    return personnelArray?.some((person) => person?.email === targetEmail);
  };
  const handleEditEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/set/${evID}`);
  };

  const CommunityResult = () => {
    if (getCommunities?.data?.length === 0 && getCommunities?.status === "ok") {
      return (
        <div className="h1">
          <Nothing title="You have no community at the moment" />
        </div>
      );
    } else {
      return (
        <div className="w-100">
          {getCommunities?.data?.map((community, i) => {
            return (
              <div
                className="w-100 mb-2 p-2 rounded-3 bg-white"
                onClick={() => {
                  Navigate(
                    `/community/${community?.communityName?.replace(
                      / /g,
                      "-"
                    )}/${community?._id}`
                  );
                }}
                key={i}
              >
                <div className="w-100">
                  <img
                    alt="community"
                    src={community?.banner ? community?.banner : communityImg}
                    className="w-100 h-100 rounded-3"
                  />
                </div>
                <div className="w-100">
                  <div className="w-100 mt-2 d-flex justify-content-between align-items-center">
                    <div className="h4">{community?.communityName}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaChevronCircleRight size={30} color="#F68A20" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };
  const myEventsSearchResult = () => {
    // console.log("filteredEvents..........", filteredEvents);
    return (
      <>
        {filteredEvents?.map((item, index) => {
          // const date = item.StartDateTime.split(" ");
          // console.log("Get all events....", item?.state);
          // console.log("Get all events....", item);

          // Event date configuration
          let dateValue = new Date(item.startDate);

          let day = dayNames[dateValue.getUTCDay()];
          let month = monthNames[dateValue.getUTCMonth()];
          let year = dateValue.getUTCFullYear();
          let date = dateValue.getUTCDate();

          let formattedDate = `${day} ${date} ${month} ${year}`;
          const isAdmin = checkForEmail(item?.personnel, `${userEmail}`);

          // if (item?.state === "Published") {
          return (
            <>
              <Col xs={12} sm={12} md={12} lg={12} key={index}>
                <div className="d-flex p-3 mb-4 shadow-sm bg-white card-brd myEvent-card-container">
                  <div
                    className="d-flex align-items-center"
                    style={{ flex: 0.4 }}
                  >
                    <div className="MyEvent_Parent_div">
                      <div className="MyEvent_image_div">
                        <img
                          src={
                            item?.bannerImage
                              ? item?.bannerImage
                              : ImagePlaceHolder
                          }
                          width="100%"
                          height="100%"
                          className="card-brd"
                          alt="event"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100 mx-2">
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      {item?.eventName}
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        fontSize: 13,
                      }}
                    >
                      {item?.eventDescription &&
                      item?.eventDescription.length > 35
                        ? `${item?.eventDescription?.substring(0, 200)}...`
                        : null}
                      {item?.eventDescription &&
                      item?.eventDescription.length <= 35
                        ? item?.eventDescription
                        : null}
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      {formattedDate} |{" "}
                      {`${item.startTime} - ${item.endTime} SAST`}
                    </div>

                    <div
                      className="mt-2 mb-1"
                      style={{
                        fontSize: 13,
                      }}
                    >
                      <ButtonSubmit
                        Title="Visit Event"
                        ColorText="#F68A20"
                        BorderColor="#F68A20"
                        BackgroundColor="white"
                        borderRadius="25px"
                        handleOnclick={() =>
                          handleAttendeeEnterEvent(item?.eventName, item?._id)
                        }
                      />
                    </div>
                    {isAdmin && (
                      <>
                        <div
                          style={{
                            fontSize: 14,
                            textDecoration: "underline",
                            color: "#0e35dd",
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleEditEvent(item?.eventName, item?._id)
                          }
                        >
                          Edit Event
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </>
          );
          // }
        })}
      </>
    );
  };
  const isViewEvent = isSwitchOn || search;

  return (
    <>
      {!isViewEvent ? (
        <>{CommunityResult()}</>
      ) : (
        <>
          {getEvents?.data?.length === 0 && getEvents?.status === "ok" ? (
            <div className="h1">
              <Nothing title="You have no event at the moment" />
            </div>
          ) : (
            <>{myEventsSearchResult()}</>
          )}
        </>
      )}
    </>
  );
}

export default AllEvents;
