import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";
import ProfilePicture from "../../../Profile/ProfilePicture";
// import { countryList } from "../../../Countries/countries";
import { title } from "../../../Common/Title/Title";
import { eventTeam } from "../../../Common/EventRole/EventRole";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function AddTeam({ eventID, token, setIsOffcanvasOpen }) {
  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});
  const key = ["organizerTeam", eventID];
  const mutation = useCustomMutation(key);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `events/team-member/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });
    if (uploadImg) {
      formdata.append("photo", uploadImg);
    }

    // const raw = JSON.stringify({
    //   firstName: inputs.firstName,
    //   lastName: inputs.lastName,
    //   email: inputs.email,
    //   phoneNumber: inputs.phoneNumber,
    //   roles: inputs.roles ? inputs.roles : "co_event-organizer",
    // });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Team member successfully invited",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <Row>
          <Col md={12}>
            <div className="d-flex mb-3 justify-content-center">
              <ProfilePicture
                showImage={showImage}
                setShowImage={setShowImage}
                setUploadImg={setUploadImg}
                iconSize={80}
                iconColor="#F68A20"
                imgHeight={80}
                imgWidth={80}
                imgBorder="50%"
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <label>
              Role <span className="text-danger">*</span>
            </label>
            <select
              name="roles"
              value={inputs.roles}
              onChange={handleChange}
              className="mb-3 form-control"
              required
            >
              <option value={null}>Select...</option>
              {eventTeam?.map((team, i) => (
                <option key={i} value={team.role}>
                  {team.name}
                </option>
              ))}
            </select>
          </Col>
          <Col md={6} lg={6}>
            <label>
              Title <span className="text-danger">*</span>
            </label>
            <select
              name="title"
              value={inputs.title}
              onChange={handleChange}
              className="mb-3 form-control"
              required
            >
              <option value={null}>Select...</option>
              {title?.map((title, i) => (
                <option key={i} value={title}>
                  {title}
                </option>
              ))}
            </select>
          </Col>
          <Col md={6} lg={6}>
            <label>
              First name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              value={inputs?.firstName}
              placeholder="Enter First name"
              onChange={handleChange}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              Last name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              value={inputs?.lastName}
              placeholder="Enter Last name"
              onChange={handleChange}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              Email <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={inputs?.email}
              placeholder="Enter email"
              onChange={handleChange}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              Phone number <span className="text-danger">*</span>
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          {/* <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Confirm Password
            </label>
            <input
              type="password"
              name="passwordConfirm"
              value={inputs?.passwordConfirm}
              // placeholder=""
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col> */}
          {/* <Col md={12} lg={12}>
            <label>
              <span className="text-danger">*</span>Organization Name
            </label>
            <input
              type="text"
              name="organization"
              value={inputs?.organization}
              placeholder="Enter organization name"
              onChange={handleChange}
              style={{ width: 630, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col> */}
          {/* <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>City
            </label>
            <input
              type="text"
              name="city"
              value={inputs?.city}
              placeholder="Enter City"
              onChange={handleChange}
              style={{ width: 300, borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col> */}
          {/* <Col md={6} lg={6}>
            <label className="event__form__Creation">
              Country
              <select
                name="country"
                value={inputs.country}
                onChange={handleChange}
                className="mb-3 form-control"
                style={{ width: 300, borderRadius: 7 }}
                required
              >
                {countryList?.map((country, i) => (
                  <option key={i}> {country} </option>
                ))}
              </select>
            </label>
          </Col> */}
          <div className="mb-3"></div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Submit"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default AddTeam;
