import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCustomFetchQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Spinner, Row, Col } from "reactstrap";
import formatDate from "../../../../utils/formatDate";

const EditTicket = ({
  ticketDetails,
  // userID,
  token,
  eventID,
  setIsOffcanvasOpen,
}) => {
  const [inputs, setInputs] = useState({});
  const [isEarlyBird, setIsEarlyBird] = useState(false);
  const queryKey = ["setTicket", eventID];
  const mutation = useCustomMutation(queryKey);

  // const {
  //   data: getMyTicketSettings,
  //   error: errorGetMyTicketSettings,
  //   isFetching: isFetchingGetMyTicketSettings,
  // } = useCustomFetchQuery(
  //   queryKey,
  //   `${process.env.REACT_APP_API}ticket-settings/${eventID}/event`,
  //   token,
  //   { enabled: !!eventID }
  // );

  // Log ticket details for debugging
  // console.log("ticketDetails:", ticketDetails);

  const handleSubmitForm = (event) => {
    event.preventDefault();

    const requestData = {
      tier: inputs.tier,
      price: parseInt(inputs.price, 10),
      quantity: parseInt(inputs.quantity, 10),
      discount: parseInt(inputs.discount, 10),
      allowEarlyBird: isEarlyBird,
      earlyBirdStartDate: formatDate(inputs.earlyBirdStartDate),
      earlyBirdEndDate: formatDate(inputs.earlyBirdEndDate),
      closingDate: formatDate(inputs.closingDate),
      ticketTerms: inputs.ticketTerms,
    };

    const config = {
      endPoint: `ticket-settings/${ticketDetails?._id}`,
      bodyContent: JSON.stringify(requestData),
      Method: "PATCH",
      displayMessage: "Ticket Successfully Updated",
      token: token,
      isJSON: null,
    };

    mutation.mutate(config);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }

    if (ticketDetails?._id) {
      const {
        allowEarlyBird,
        discount,
        price,
        quantity,
        ticketTerms,
        tier,
        earlyBirdStartDate,
        earlyBirdEndDate,
        closingDate,
      } = ticketDetails;

      // Validate and parse date strings to ensure they are in proper format
      const parseDate = (dateString) =>
        dateString ? new Date(dateString).toISOString().substring(0, 10) : "";

      setInputs({
        allowEarlyBird,
        discount,
        price,
        quantity,
        ticketTerms,
        tier,
        closingDate: parseDate(closingDate),
        earlyBirdStartDate: parseDate(earlyBirdStartDate),
        earlyBirdEndDate: parseDate(earlyBirdEndDate),
      });

      setIsEarlyBird(!!allowEarlyBird);
    }
  }, [mutation?.isSuccess, ticketDetails]);

  return (
    <div className="w-100">
      <form onSubmit={handleSubmitForm}>
        <Row>
          <Col md={4}>
            <label>Ticket Name</label>
            <input
              type="text"
              name="tier"
              className="mb-3 form-control"
              value={inputs?.tier || ""}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={4}>
            <label>Price</label>
            <input
              type="number"
              name="price"
              className="mb-3 form-control"
              value={inputs?.price || ""}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={4}>
            <label>Quantity</label>
            <input
              type="number"
              name="quantity"
              className="mb-3 form-control"
              value={inputs?.quantity || ""}
              onChange={handleChange}
              required
            />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <label>Ticket Closing Date</label>
            <input
              type="date"
              name="closingDate"
              className="mb-3 form-control"
              value={inputs.closingDate || ""}
              onChange={handleChange}
              required
            />
          </Col>
        </Row>

        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="earlyBirdCheckbox"
            onChange={() => setIsEarlyBird(!isEarlyBird)}
            checked={isEarlyBird}
          />
          <label className="form-check-label mx-2" htmlFor="earlyBirdCheckbox">
            Include Early Bird Price
          </label>
        </div>

        {isEarlyBird && (
          <div className="mt-2">
            <Row>
              <Col md={4}>
                <label>Early Bird Start Date</label>
                <input
                  type="date"
                  name="earlyBirdStartDate"
                  className="mb-3 form-control"
                  value={inputs.earlyBirdStartDate || ""}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={4}>
                <label>Early Bird End Date</label>
                <input
                  type="date"
                  name="earlyBirdEndDate"
                  className="mb-3 form-control"
                  value={inputs.earlyBirdEndDate || ""}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={4}>
                <label>Discount (%)</label>
                <input
                  type="number"
                  name="discount"
                  className="mb-3 form-control"
                  value={inputs.discount || ""}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
          </div>
        )}

        <Row>
          <Col md={12}>
            <label>Refund Policy</label>
            <textarea
              rows={5}
              className="form-control"
              name="ticketTerms"
              value={inputs.ticketTerms || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <div className="d-flex mt-2 justify-content-center align-items-center">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" /> Loading
              </>
            ) : (
              "Update Ticket"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTicket;
