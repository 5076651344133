import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomQuery from "../../../Hooks/useCustomQuery";
import HeaderNavigation from "../../../Layout/HorizontalLayout/CommunityHeader";
import ReceptionHeader from "./Header";
import logo from "../../../assets/images/community/Logo.png";
import Reception from "../../../components/Expo/BackStage/Reception";
// import HeaderNa
// import BackStageComponent from "../../../components/Webinar/BackStage/BackStage";

const BackStage = () => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userName = obj?.data?.data?.firstName;
  //   const userId = obj?.data?.data?.id;
  const token = obj?.data?.token;
  const params = useParams();

  const eventID = params.eventID;
  const eventName = params.eventName;
  const myEventName = eventName.replace(/-/g, " ");
  document.title = `${myEventName} | Backstage`;
  const [activeLink, setActiveLink] = useState(0);
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  // console.log("community......", getMyEventDetail?.data?.communityId?._id);
  return (
    <div className="w-100 webinar-backstage">
      <HeaderNavigation
        logoImg={logo}
        communityID={getMyEventDetail?.data?.communityId?._id}
      />

      <ReceptionHeader
        activeLink={activeLink}
        setActiveLink={setActiveLink}
        handleNavigation={handleLinkClick}
        eventDetail={getMyEventDetail?.data}
      />

      {activeLink === 0 && (
        <Reception
          userName={userName}
          token={token}
          eventID={eventID}
          eventDetail={getMyEventDetail?.data}
        />
      )}
    </div>
  );
};

export default BackStage;
