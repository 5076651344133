import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
// import CustomModal from "../../../../components/Modal/CustomModal";
import FullModal from "../../../../components/Modal/FullModal";
import useCustomQuery from "../../../../Hooks/useCustomQuery";
import "./css/SelectOption.scss";
import Conference from "./Conference";
import Exhibition from "./Exhibition";
import Webinar from "./Webinar";
import Expo from "./Expo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { pricing } from "../../../../components/Common/eventPackage/EventPackage";

function SelectOption({}) {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userID = obj?.data?.data?._id;
  const userEmail = obj?.data?.data?.email;
  const token = obj?.data?.token;

  const [eventType, setEventType] = useState(null);
  const [eventFormat, setEventFormat] = useState(null);
  const [isWebinar, setIsWebinar] = useState(false);
  const [isConference, setIsConference] = useState(false);
  const [isExhibition, setIsExhibition] = useState(false);
  const [isExpo, setIsExpo] = useState(false);

  const toggleWebinar = () => {
    setIsWebinar(!isWebinar);
  };
  const toggleConference = () => {
    setIsConference(!isConference);
  };
  const toggleExhibition = () => {
    setIsExhibition(!isExhibition);
  };
  const toggleExpo = () => {
    setIsExpo(!isExpo);
  };
  const toggleCreateEvent = (type, format) => {
    if (type === "Webinar") {
      toggleWebinar();
    }
    if (type === "Conference") {
      toggleConference();
    }
    if (type === "Exhibition") {
      toggleExhibition();
    }
    if (type === "Expo") {
      toggleExpo();
    }
    setEventType(type);
    setEventFormat(format);
    // setIsEvent(!isEvent);
  };

  // const {
  //   data: getMyPackages,
  //   error: errorGetMyPackage,
  //   isLoading: isLoadingGetMyPackage,
  // } = useCustomQuery(
  //   ["myEventPackages", userID],
  //   `${process.env.REACT_APP_API}packages/lead/?email=${userEmail}`,
  //   token
  // );

  const {
    data: getCommunities,
    error: errorGetCommunities,
    isLoading: isLoadingGetCommunities,
  } = useCustomQuery(
    ["MyCommunities", userEmail],
    `${process.env.REACT_APP_API}communities/user/${userEmail}`,
    token
  );

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <div className="mt-4 mx-3">
      <div>
        <h1>Create Your Event</h1>
        <div>Select what kind of event you would like to create</div>
      </div>
      <div className="">
        <Slider {...settings}>
          {pricing?.map((item, key) => (
            <div
              style={{ width: 200, height: 450, backgroundColor: "blue" }}
              key={key}
            >
              <Card className="w-100" style={{ width: 200 }}>
                <CardBody className="p-4">
                  <div className="d-flex mb-1">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-sm">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={item.icon + " font-size-20"}></i>
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-16">{item.eventType}</h5>
                      <p className="text-muted">{item.eventFormat}</p>
                    </div>
                  </div>
                  <div className="py-4 border-bottom">
                    <div className="float-end plan-btn">
                      <button
                        className="btn btn-primary btn-sm waves-effect waves-light"
                        onClick={() =>
                          toggleCreateEvent(item.eventType, item.eventFormat)
                        }
                        // onClick={() => {
                        //   if (item.eventType === "Conference") {
                        //     toggleSlideInConference();
                        //   }
                        //   if (item.eventType === "Exhibition") {
                        //     toggleSlideInExhibition();
                        //   }
                        //   if (item.eventType === "Webinar") {
                        //     toggleSlideInWebinar();
                        //   }
                        //   if (item.eventType === "Expo") {
                        //     toggleSlideInExpo();
                        //   }
                        // }}
                      >
                        Create Event
                      </button>
                    </div>
                    <h4>
                      <sup>
                        <small>R</small>
                      </sup>
                      {item.price}
                      {/* {item.price}/ <span className="font-size-16">m</span> */}
                    </h4>
                  </div>
                  <div className="plan-features mt-4">
                    <h5 className="text-center font-size-15 mb-4">
                      Plan Features :
                    </h5>
                    {item.isChild.map((subitem, key) => (
                      <p key={key}>
                        <i className="mdi mdi-checkbox-marked-circle-outline font-size-16 align-middle text-primary me-2"></i>{" "}
                        {subitem.features}
                      </p>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
      <FullModal
        isOpen={isConference}
        toggleEvent={toggleConference}
        title="Create Conference Event"
        body={
          <Conference
            eventType={eventType}
            eventFormat={eventFormat}
            userId={userID}
            token={token}
            getCommunities={getCommunities?.data}
          />
        }
      />
      <FullModal
        isOpen={isExhibition}
        toggleEvent={toggleExhibition}
        // title="Create Exhibiton Event"
        body={
          <Exhibition
            eventType={eventType}
            eventFormat={eventFormat}
            userId={userID}
            token={token}
            getCommunities={getCommunities?.data}
          />
        }
      />
      <FullModal
        isOpen={isExpo}
        toggleEvent={toggleExpo}
        // title="Create Expo"
        body={
          <Expo
            eventType={eventType}
            eventFormat={eventFormat}
            userId={userID}
            token={token}
            getCommunities={getCommunities?.data}
          />
        }
      />

      <FullModal
        isOpen={isWebinar}
        toggleEvent={toggleWebinar}
        body={
          <Webinar
            eventType={eventType}
            eventFormat={eventFormat}
            userId={userID}
            token={token}
            getCommunities={getCommunities?.data}
          />
        }
      />
    </div>
  );
}

export default SelectOption;
