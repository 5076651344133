import React from "react";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import "./swiperStyle.scss";

import Divider from "./Divider";

const SpeakerCard = ({ title, name, role, description, image }) => {
  return (
    <div className="d-flex speaker-card justify-content-center align-items-center">
      <div className="speaker-image">
        <img src={image} alt={name} />
      </div>
      <div className="mx-2 speaker-img-text">
        <div className="speaker-img-Title">
          {`${title} `} {name}
        </div>
        <div className="speaker-img-Role">{role}</div>
        {description?.length < 30 ? (
          <div className="mt-2 speaker-img-description">{description}</div>
        ) : (
          <div className="mt-2 speaker-img-description">
            {`${description.substring(0, 35)}...`}
            <span
              style={{ fontSize: 13, fontWeight: "bold", color: "#f68a20" }}
            >
              More
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const Speakers = ({ token, eventID }) => {
  const {
    data: getMySpeakers,
    error: errorGetMySpeakers,
    isLoading: isLoadingGetMySpeakers,
  } = useCustomFecthQuery(
    ["mySpeakers", eventID],
    `${process.env.REACT_APP_API}speakers/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  if (getMySpeakers?.status === "ok" && getMySpeakers?.data?.length === 0) {
    return null;
  }

  // console.log(".....getMySpeakers.....", getMySpeakers?.data);

  return (
    <React.Fragment>
      <h1
        id="speakers"
        className="d-flex justify-content-center"
        style={{ color: "black", fontWeight: "bold" }}
      >
        Our Speakers
      </h1>
      <Divider />
      <div className="w-100 mt-2 mb-2 d-flex justify-content-content">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          slidesPerView={3}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          // breakpoints={{
          //   400: {
          //     slidesPerView: 1,
          //   },
          //   640: {
          //     slidesPerView: 1,
          //   },
          //   768: {
          //     slidesPerView: 2,
          //   },
          //   1000: {
          //     slidesPerView: 2,
          //   },
          //   1100: {
          //     slidesPerView: 3,
          //   },
          // }}
          modules={[Autoplay, Pagination]}
          className="speakers-swiper"
        >
          {getMySpeakers?.data?.map((speaker, index) => (
            <SwiperSlide key={index}>
              <SpeakerCard
                title={speaker.title}
                name={speaker.firstName}
                role={speaker.jobTitle}
                description={speaker.speakerBiography}
                image={speaker.photo}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <Swiper
          className="speakers-swiper"
          spaceBetween={30}
          slidesPerView={3}
          pagination={{ clickable: true }}
        >
          {getMySpeakers?.data?.map((speaker, index) => (
            <SwiperSlide key={index}>
              <SpeakerCard
                name={speaker.firstName}
                role={speaker.jobTitle}
                description={speaker.speakerBiography}
                image={speaker.photo}
              />
            </SwiperSlide>
          ))}
        </Swiper> */}
      </div>
    </React.Fragment>
  );
};

export default Speakers;
