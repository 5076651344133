import React, { useState, useRef, useEffect } from "react";
import { Spinner, Button } from "reactstrap";
import { BiEdit } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.min.css";

// import useCustomQuery from "../../../../../../../Hooks/useCustomQuery";
import useCustomMutation from "../../../../../../../Hooks/useCustomMutation";
import ImgSession from "../../../../../../../assets/images/banners/sessions/bannerSession.png";
import "../../../css/Summary.scss";
import "./company.scss";

const EditCompany = ({ eventID, toggle, hostDetails }) => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const token = obj?.data?.token;
  const key = ["my-Hosts", eventID];
  // const key = ["myHosts", eventID];
  const mutation = useCustomMutation(key);

  const [isPicture, setIsPicture] = useState(false);
  const [Banner, setBanner] = useState(false);
  const [BannerServer, setBannerServer] = useState();
  const [profile, setProfile] = useState(null);
  const firstName = obj?.data?.data?.firstName;
  const lastName = obj?.data?.data?.lastName;
  const [email, setEmail] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const refFileUpload = useRef("");

  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setBannerServer(event.target.files[0]);
      setIsPicture(false);

      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        setBanner(loadEvent.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const Method = "PATCH",
      url = `users/${hostDetails?._id}`,
      isJSON = true;
    const formdata = new FormData();
    if (BannerServer) {
      formdata.append("photo", BannerServer);
    }
    formdata.append("firstName", firstName);
    formdata.append("lastName", lastName);
    formdata.append("organizationName", organizationName);
    formdata.append("email", email);
    formdata.append("phoneNumber", phoneNumber);

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Host Successfully Updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      toggle(false);
    }
    if (hostDetails) {
      if (hostDetails?.photo) {
        setBannerServer(hostDetails?.photo);
        setIsPicture(true);
      }

      setOrganizationName(hostDetails?.organizationName);
      setEmail(hostDetails?.email);
      setPhoneNumber(hostDetails?.phoneNumber);
    }
  }, [mutation.isSuccess]);

  console.log("profile.....", profile);
  return (
    <React.Fragment>
      <div className="booth__body">
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          className="booth__body--form"
          onSubmit={handleSubmitForm}
        >
          <div className="mt-0">
            <div className="d-flex justify-content-center">
              <div className="section---banner---container mt-2">
                <img
                  src={isPicture ? BannerServer : Banner}
                  alt="session Image"
                  className="w-100 h-100 section---banner---container---img"
                />
                <Button
                  size="md"
                  variant="separator-light"
                  className="btn-icon btn-icon-only  rounded  s-0 b-0 mt-1 border-0 btnUploadEventBanner "
                  onClick={onThumbChangeClick}
                >
                  <BiEdit
                    size={40}
                    style={{
                      color: "black",
                      filter: "drop-shadow(0px 0px 10px white)",
                    }}
                  />
                </Button>
                <input
                  type="file"
                  ref={refFileUpload}
                  className="file-upload d-none"
                  accept="image/*"
                  onChange={changeThumb}
                />
              </div>
            </div>
            <div
              className="sponsor-form-body__sponsor-logo"
              style={{ marginRight: "12px", marginBottom: "-16px" }}
            >
              <p
                color="ambience.6"
                font-size="1"
                className="sponsor-form-body--input-description"
              >
                Recommended format: 900x900px | JPG, SVG, PNG | Up to 1 MB
              </p>
            </div>
            {/* ------------------ Form Fields ---------------------- */}

            <div className="booth__body--form-input-container">
              <label
                htmlFor="organizationName"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Organization Name*</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="organizationName"
                  name="organizationName"
                  type="text"
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                  required
                  placeholder="Organization Name"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="email"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Organization Email</p>
                <p className="input-description">
                  This session will be shown to attendees on the event landing
                  page
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="email"
                  name="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Organization Email"
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            <div className="booth__body--form-input-container">
              <label
                htmlFor="phoneNumber"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Organization Contact Number</p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                />
              </div>
            </div>

            {/* <div className="booth__body--form-input-container">
              <label
                htmlFor="biography"
                className="booth__body--form-input-container__label"
              >
                <p className="input-heading">Company Description</p>
                <p className="input-description">
                  Bio of the host and their achievements.
                </p>
              </label>
              <div className="single-attendee__body--form-input-container gzIdbH">
                <input
                  id="description"
                  name="description"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder=""
                  className="single-attendee__body--form-input-container-field"
                  onFocus={(e) => e.target.classList.add("active")}
                  onBlur={(e) =>
                    e.target.value === "" && e.target.classList.remove("active")
                  }
                  style={{ minHeight: 100 }}
                />
              </div>
            </div> */}
          </div>
          <div className="next-container" style={{ color: "#fff" }}>
            <button
              className="btn btn-primary"
              type="submit"
              style={{ color: "#fff" }}
            >
              <div className="d-flex justify-content-center align-items-center">
                {!mutation.isLoading ? <span>Update</span> : null}
                {!mutation.isLoading ? null : (
                  <span>
                    <Spinner as="span" animation="border" size="sm" /> Loading
                  </span>
                )}
              </div>
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default EditCompany;
