import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './swipperStyle.css';

import Exhibition from "../Loader/EventSlide/Exhibition";
import Conference from "../Loader/EventSlide/Conference";
import Webinar from "../Loader/EventSlide/Webinar";

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

export default function Slide
() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide className="bg-primary"><Exhibition/></SwiperSlide>
        <SwiperSlide className="bg-primary"><Conference/></SwiperSlide>
        <SwiperSlide className="bg-primary"><Webinar/></SwiperSlide>
      </Swiper>
    </>
  );
}