// import React, { useEffect, useState, useContext } from "react";
// // import React, { useEffect } from "react";
// import CryptoJS from "crypto-js";
// import { UserTicketContext } from "../../../helpers/UserTicketContext";
// import ButtonGradient from "../../Buttons/ButtonGradient";

// function generateSignature(data, passPhrase = null) {
//   // Create parameter string
//   let pfOutput = "";
//   for (let key in data) {
//     if (data.hasOwnProperty(key)) {
//       if (data[key] !== "") {
//         pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
//           /%20/g,
//           "+"
//         )}&`;
//       }
//     }
//   }

//   // Remove last ampersand
//   let getString = pfOutput.slice(0, -1);
//   if (passPhrase !== null) {
//     getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
//       /%20/g,
//       "+"
//     )}`;
//   }

//   // Generate MD5 hash using crypto-js
//   return CryptoJS.MD5(getString).toString(CryptoJS.enc.Hex);
// }

// const TicketDetails = ({ ticket }) => {
//   const { customerDetails } = useContext(UserTicketContext);
//   // console.log("customerDetails.name_first....", customerDetails.name_first);
//   useEffect(() => {
//     const encodedNameFirst = encodeURIComponent(customerDetails?.name_first);
//     const encodedNameLast = encodeURIComponent(customerDetails?.name_last);
//     const encodedEmail = encodeURIComponent(customerDetails?.email_address);
//     const encodedPhone = encodeURIComponent(customerDetails?.phoneNumber);
//     const returnUrl = `http://localhost:3000/registrations?name_first=${customerDetails?.name_first}&name_last=${customerDetails?.name_last}&email=${customerDetails?.email_address}&phone=${customerDetails?.phoneNumber}`;
//     console.log("returnUrl....", returnUrl);
//     // Merchant details
//     const myData = {
//       merchant_id: "10000100",
//       merchant_key: "46f0cd694581a",
//       return_url: returnUrl,
//       cancel_url: "http://localhost:3000/cancel",
//       notify_url: "http://localhost:3000/notify",
//       name_first: customerDetails.name_first,
//       name_last: customerDetails.name_last,
//       email_address: customerDetails.email_address,
//       m_payment_id: "1234",
//       amount: `${ticket?.price}`,
//       item_name: "Order#123",
//     };

//     // Generate signature
//     const myPassphrase = "jt7NOE43FZPn";
//     myData.signature = generateSignature(myData, myPassphrase);

//     // Create form element
//     const form = document.createElement("form");
//     form.action = "https://sandbox.payfast.co.za/eng/process";
//     form.method = "post";
//     form.id = "payment-form"; // Assign an ID to the form for easy reference

//     // Create hidden input elements
//     for (const [key, value] of Object.entries(myData)) {
//       if (value !== "") {
//         const hiddenInput = document.createElement("input");
//         hiddenInput.type = "hidden";
//         hiddenInput.name = key;
//         hiddenInput.value = value.trim();
//         form.appendChild(hiddenInput);
//       }
//     }

//     // Append the form to the container
//     document.getElementById("checkout-container").appendChild(form);
//   }, []);

//   // Function to handle custom button click
//   const handlePayNow = () => {
//     document.getElementById("payment-form").submit(); // Submit the form on button click
//   };

//   return (
//     <div className="checkout" id="checkout-container">
//       <div className="order"></div>
//       <div className="h4">Ticket Details</div>
//       <div className="mt-2" style={{ fontSize: 15, fontWeight: "bolder" }}>
//         {ticket?.ticketName}
//       </div>
//       <div
//         className="mt-2 mb-2 text-primary"
//         style={{ fontSize: 22, fontWeight: "bolder" }}
//       >
//         R {ticket?.price}
//       </div>
//       {ticket?.ticketTerms && (
//         <>
//           <div style={{ fontSize: 18, fontWeight: "bold" }}>Note</div>
//           <div style={{ fontSize: 13 }}>{ticket?.ticketTerms}</div>
//         </>
//       )}
//       {/* Custom Pay Now button */}
//       {customerDetails.name_first &&
//         customerDetails.name_last &&
//         customerDetails.phoneNumber && (
//           <>
//             <ButtonGradient
//               type="submit"
//               Title="Pay Now"
//               leftColor="#f7983c"
//               rightColor="#ff7c6e"
//               ColorText="white"
//               BorderColor="transparent"
//               borderRadius={50}
//               handleOnclick={handlePayNow}
//             />
//           </>
//         )}
//     </div>
//   );
// };

// export default TicketDetails;

import React, { useContext } from "react";
import CryptoJS from "crypto-js";
import { UserTicketContext } from "../../../helpers/UserTicketContext";
import ButtonGradient from "../../Buttons/ButtonGradient";

function generateSignature(data, passPhrase = null) {
  let pfOutput = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== "") {
        pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
          /%20/g,
          "+"
        )}&`;
      }
    }
  }

  let getString = pfOutput.slice(0, -1);
  if (passPhrase !== null) {
    getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
      /%20/g,
      "+"
    )}`;
  }

  return CryptoJS.MD5(getString).toString(CryptoJS.enc.Hex);
}

const TicketDetails = ({ ticket, eventID }) => {
  const { customerDetails } = useContext(UserTicketContext);

  const handlePayNow = () => {
    const encodedNameFirst = encodeURIComponent(customerDetails?.name_first);
    const encodedNameLast = encodeURIComponent(customerDetails?.name_last);
    const encodedEmail = encodeURIComponent(customerDetails?.email_address);
    const encodedPhone = encodeURIComponent(customerDetails?.phoneNumber);
    const encodedEventID = encodeURIComponent(eventID);

    const returnUrl = `https://demoevents.kago.digital/registrations?name_first=${encodedNameFirst}&name_last=${encodedNameLast}&email=${encodedEmail}&phone=${encodedPhone}&ev=${encodedEventID}`;

    const myData = {
      merchant_id: "10000100",
      merchant_key: "46f0cd694581a",
      return_url: returnUrl,
      cancel_url: `https://demoevents.kago.digital/cancellation?&ev=${encodedEventID}`,
      notify_url: `https://demoevents.kago.digital/notify?&ev=${encodedEventID}`,
      name_first: customerDetails.name_first,
      name_last: customerDetails.name_last,
      email_address: customerDetails.email_address,
      m_payment_id: "1234",
      amount: `${ticket?.price}`,
      item_name: ticket?.ticketName,
    };

    const myPassphrase = "jt7NOE43FZPn";
    myData.signature = generateSignature(myData, myPassphrase);

    const form = document.createElement("form");
    form.action = "https://sandbox.payfast.co.za/eng/process";
    form.method = "post";
    form.id = "payment-form";

    for (const [key, value] of Object.entries(myData)) {
      if (value !== "") {
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = key;
        hiddenInput.value = value.trim();
        form.appendChild(hiddenInput);
      }
    }

    const checkoutContainer = document.getElementById("checkout-container");

    // Check if checkoutContainer exists before appending the form
    if (checkoutContainer) {
      checkoutContainer.appendChild(form);
      form.submit();
    } else {
      console.error("Checkout container not found.");
    }
  };

  return (
    <div className="checkout" id="checkout-container">
      <div className="order"></div>
      <div className="h4">Ticket Details</div>
      <div className="mt-2" style={{ fontSize: 15, fontWeight: "bolder" }}>
        {ticket?.ticketName}
      </div>
      <div
        className="mt-2 mb-2 text-primary"
        style={{ fontSize: 22, fontWeight: "bolder" }}
      >
        R {ticket?.price}
      </div>
      {ticket?.ticketTerms && (
        <>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Note</div>
          <div style={{ fontSize: 13 }}>{ticket?.ticketTerms}</div>
        </>
      )}
      {customerDetails.name_first &&
        customerDetails.name_last &&
        customerDetails.phoneNumber && (
          <ButtonGradient
            type="button"
            Title="Pay Now"
            leftColor="#f7983c"
            rightColor="#ff7c6e"
            ColorText="white"
            BorderColor="transparent"
            borderRadius={50}
            handleOnclick={handlePayNow}
          />
        )}
    </div>
  );
};

export default TicketDetails;
