import React from "react";
import useCustomQuery from "../../Hooks/useCustomQuery";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import Loader from "../../components/Loader/Loader";
import Main from "./components/Main";

const Index = () => {
  document.title = "Kago Events | Ticket";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const token = obj?.data?.token;
  const params = useParams();

  const eventID = params.eventID;
  const eventName = params.eventName;
  // const myEventName = eventName.replace(/-/g, " ");

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomQuery(
    ["myEventDetail", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token
  );

  if (isLoadingGetMyEventDetail) {
    <div className="page-content mt-5 d-flex justify-content-center align-items-center">
      <Loader />
    </div>;
  }
  return (
    <React.Fragment>
      <div className="page-content" style={{ userSelect: "none" }}>
        <Main eventID={eventID} eventDetails={getMyEventDetail} token={token} />
      </div>
    </React.Fragment>
  );
};

export default Index;
