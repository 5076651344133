import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { LuUser2 } from "react-icons/lu";
import { IoTicketOutline } from "react-icons/io5";
import { TbFileInvoice } from "react-icons/tb";
import { TiContacts, TiBusinessCard } from "react-icons/ti";
import { BsBank2 } from "react-icons/bs";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

// users
import userImg from "../../../assets/images/users/User.png";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  // const [username, setUsername] = useState("Admin");
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const name = obj?.data?.data?.firstName;
  const userImage = obj?.data?.data?.photo;
  const role = obj?.data?.data?.roles;

  // console.log("obj....", obj?.data?.data?.roles);

  const capitalizedFirstName = name
    ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    : "";

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              className="rounded-circle header-profile-user"
              src={userImage ? userImage : userImg}
              alt="Header Avatar"
            />

            <div
              className="d-none d-xl-inline-block ms-2 me-2"
              style={{ color: "black" }}
            >
              {capitalizedFirstName}
            </div>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end"
          // style={{
          //   backgroundColor: props.color ? "#212734" : "white",
          //   // color: props.color ? "white" : "#212734",
          // }}
        >
          <DropdownItem tag="a">
            <Link to="/userprofile" className="text-dark">
              <div style={{ display: "flex", alignItems: "center" }}>
                <LuUser2 size={16} color="#f68a20" />
                <div className="mx-1">{props.t("Profile")}</div>
              </div>
            </Link>
          </DropdownItem>
          {role?.includes("event_organizer") ? (
            <DropdownItem tag="a">
              <Link to="/merchant-details" className="text-dark">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BsBank2 size={16} color="#f68a20" />
                  <div className="mx-1">{props.t("Ticket Payment")}</div>
                </div>
              </Link>
            </DropdownItem>
          ) : null}

          <DropdownItem tag="a" href="/my-business-card">
            <div style={{ display: "flex", alignItems: "center" }}>
              <TiBusinessCard size={16} color="#f68a20" />
              <div className="mx-1">{props.t("Business Card")}</div>
            </div>
          </DropdownItem>
          <DropdownItem tag="a" href="/my-contacts">
            <div style={{ display: "flex", alignItems: "center" }}>
              <TiContacts size={18} color="#f68a20" />
              <div className="mx-1">{props.t("My Contacts")}</div>
            </div>
          </DropdownItem>
          <DropdownItem tag="a" href="/my-tickets">
            <div style={{ display: "flex", alignItems: "center" }}>
              <IoTicketOutline size={16} color="#f68a20" />
              <div className="mx-1">{props.t("My Tickets")}</div>
            </div>
          </DropdownItem>
          <DropdownItem tag="a" href="/my-invoices">
            <div style={{ display: "flex", alignItems: "center" }}>
              <TbFileInvoice size={16} color="#f68a20" />
              <div className="mx-1">{props.t("My Invoices")}</div>
            </div>
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <div style={{ display: "flex", alignItems: "center" }}>
              <i className="ri-shut-down-line align-middle me-2 text-danger" />
              <div>{props.t("Logout")}</div>
            </div>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
