import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useCustomFetchQuery from "../../../../Hooks/useCustomFecthQuery";
import usePost from "../../../../Hooks/usePost";
import Divider from "./Divider";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";
import TermsAndCondition from "./TermsAndCondition";

const FreeRegisterNow = ({
  eventID,
  firstName,
  lastName,
  email,
  phone,
  redirect,
}) => {
  const [termsModal, setTermsModal] = useState(false);
  const [formContent, setFormContent] = useState([]);
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false); // Checkbox state for terms agreement
  const { status, execute, pending } = usePost();
  const navigate = useNavigate();

  const {
    data: eventForm,
    error: eventFormError,
    isLoading: isEventFormLoading,
  } = useCustomFetchQuery(
    ["eventForm", eventID],
    `${process.env.REACT_APP_API}event-form/${eventID}`,
    null,
    { enabled: !!eventID }
  );

  useEffect(() => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(email && { email }),
      ...(phone && { phoneNumber: phone }),
    }));
  }, [firstName, lastName, email, phone]);

  const handleRegister = (e) => {
    e.preventDefault();

    let isValid = true;
    const newErrors = {};

    // First name validation
    if (!inputs.firstName) {
      newErrors.firstName = "First name is required.";
      isValid = false;
    }

    // Last name validation
    if (!inputs.lastName) {
      newErrors.lastName = "Last name is required.";
      isValid = false;
    }

    // Phone number validation
    if (!inputs.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required.";
      isValid = false;
    }

    // Email validation (basic regex to check format)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!inputs.email) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!emailRegex.test(inputs.email)) {
      newErrors.email = "Please enter a valid email.";
      isValid = false;
    }

    // Terms and conditions checkbox validation
    if (!isChecked) {
      newErrors.checkbox = "You must agree to the terms and conditions.";
      isValid = false;
    }

    setErrors(newErrors);

    if (!isValid) return;

    const customFields = formContent.slice(4)?.map((field) => ({
      label: field.label,
      value: inputs[field.name] || "",
    }));

    const registrationData = {
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      phoneNumber: inputs.phoneNumber,
      customFields: customFields,
    };

    execute(
      `attendees/add/${eventID}`,
      JSON.stringify(registrationData),
      "POST",
      "Successfully Registered",
      null
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handlePhoneChange = (value) => {
    setInputs((prevInputs) => ({ ...prevInputs, phoneNumber: value }));
    setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: "" }));
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setErrors((prevErrors) => ({ ...prevErrors, checkbox: "" }));
  };

  const openPolicy = () => {
    setTermsModal(!termsModal);
  };

  useEffect(() => {
    if (eventForm?.data?.[0]?._id) {
      setFormContent(eventForm.data[0].customFields);
    }

    if (status === "success" && eventID === "66dac16799a716e245987fc3") {
      window.location.href = `${redirect}`;
    }
    // else {
    //   navigate(`/login/e/${eventID}`);
    // }
  }, [eventForm?.data?.[0]?._id, status]);

  return (
    <React.Fragment>
      <div className="w-100 mt-2 d-flex justify-content-center">
        <h1
          id="schedule"
          className="d-flex justify-content-center"
          style={{ color: "black", fontWeight: "bold" }}
        >
          RSVP Now
        </h1>
      </div>
      <Divider />
      <div className="w-100 mt-4 mb-3 d-flex justify-content-center">
        <div
          style={{
            width: 650,
            backgroundColor: "white",
            padding: 20,
            borderRadius: 10,
          }}
        >
          <div className="w-100 px-4 mb-2 mt-2">
            <form noValidate autoComplete="off" onSubmit={handleRegister}>
              <Row>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      name="firstName"
                      type="text"
                      value={inputs.firstName}
                      className="form-control"
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <div className="text-danger">{errors.firstName}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      name="lastName"
                      type="text"
                      value={inputs.lastName}
                      className="form-control"
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <div className="text-danger">{errors.lastName}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      type="email"
                      value={inputs.email}
                      className="form-control"
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-100 mb-2">
                    <label>
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <div className="phone-input-container">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="ZA"
                        value={inputs.phoneNumber}
                        onChange={handlePhoneChange}
                        className="custom-phone-input"
                      />
                    </div>
                    {errors.phoneNumber && (
                      <div className="text-danger">{errors.phoneNumber}</div>
                    )}
                  </div>
                </Col>

                {formContent.slice(4)?.map((field) => (
                  <Col md={12} key={field._id}>
                    <div className="mb-2">
                      <label>{field.label}</label>
                      {field.question_type === "text" && (
                        <input
                          type="text"
                          className="form-control"
                          placeholder={field.label}
                          name={field.name}
                          value={inputs[field.name] || ""}
                          onChange={handleChange}
                        />
                      )}
                      {field.question_type === "short_answer" && (
                        <input
                          type="text"
                          className="form-control"
                          placeholder={field.label}
                          name={field.name}
                          value={inputs[field.name] || ""}
                          onChange={handleChange}
                        />
                      )}
                      {field.question_type === "paragraph" && (
                        <textarea
                          rows={5}
                          className="form-control"
                          placeholder={field.label}
                          name={field.name}
                          value={inputs[field.name] || ""}
                          onChange={handleChange}
                        />
                      )}
                      {field.question_type === "options" && (
                        <select
                          className="form-control"
                          name={field.name}
                          value={inputs[field.name] || ""}
                          onChange={handleChange}
                        >
                          <option>Select...</option>
                          {field.list.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>

              <div className="w-100 mt-3 mb-2">
                <div className="d-flex">
                  <div className="mt-1">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <div className="ms-2">
                    {errors.checkbox && (
                      <div className="text-danger">{errors.checkbox}</div>
                    )}
                    <span
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      onMouseOver={(e) =>
                        (e.target.style.textDecoration = "underline")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.textDecoration = "none")
                      }
                      onClick={openPolicy}
                    >
                      By signing up for and using Kago Events, you agree to the
                      collection, use, and sharing of your data as described in
                      this Data Usage Policy. If you do not agree with this
                      policy, please do not register or use the software.
                    </span>
                  </div>
                </div>
              </div>

              <div className="w-100 mt-3 mb-2 d-flex justify-content-center">
                <ButtonSubmit
                  Title="Submit"
                  ColorText="white"
                  BorderColor="#F68A20"
                  BackgroundColor="#F68A20"
                  borderRadius="25px"
                  handleOnclick={handleRegister}
                  pending={pending}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal size="lg" isOpen={termsModal} toggle={openPolicy}>
        <ModalHeader toggle={openPolicy}>Data Usage Policy</ModalHeader>
        <ModalBody>
          <TermsAndCondition />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default FreeRegisterNow;
