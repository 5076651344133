import React, { useState } from "react";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import OffCanvas from "../../components/OffCanvas/OffCanvas";
import ButtonStyle from "../../components/Buttons/ButtonSubmit";
import Nothing from "../../components/Loader/Nothing";
import CreateBusinessCard from "../../components/BusinessCard/CreateBusinessCard";
import EditBusinessCard from "../../components/BusinessCard/EditBusinessCard";
import QRCode from "react-qr-code";
import { LiaUser } from "react-icons/lia";
// import { MdEmail } from "react-icons/md";
// import { FaPhoneAlt } from "react-icons/fa";
function BusinessCard() {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userID = obj?.data?.data?._id;
  const token = obj?.data?.token;
  const {
    data: getMyCard,
    error: errorGetMyCard,
    isLoading: isLoadingGetMyCard,
  } = useCustomFecthQuery(
    ["MyCard", userID],
    `${process.env.REACT_APP_API}contacts/?uid=${userID}`,
    token,
    { enabled: !!userID }
  );

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [isEditCard, setIsEditCard] = useState(false);
  const toggleSaveChanges = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };
  const toggleEditChanges = () => {
    setIsEditCard(!isEditCard);
  };

  console.log("getMyCard....", getMyCard?.data);

  return (
    <div className="page-content">
      {getMyCard?.status === "ok" && getMyCard?.data?.length === 0 ? (
        <div className="w-100">
          <div className="w-100 d-flex justify-content-center">
            <Nothing title="No Business Card" />
          </div>
          <div className="w-100 p-2 d-flex justify-content-center">
            <ButtonStyle
              Title="Create Card"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={false}
            />
          </div>
        </div>
      ) : null}

      {getMyCard?.status === "ok" && getMyCard?.data ? (
        <>
          <div className="w-100 d-flex justify-content-end">
            <ButtonStyle
              Title="Edit Business Card"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleEditChanges}
              pending={false}
            />
          </div>

          <div className="w-100 d-flex justify-content-center">
            <div
              style={{
                width: 300,
                height: 370,
                borderRadius: 10,
                backgroundColor: "#fbfbfb",
                userSelect: "none",
              }}
            >
              <div
                className="mt-3 d-flex justify-content-center"
                style={{
                  fontSize: 16,
                  fontWeight: "800",
                  color: "#F68A20",
                }}
              >
                {getMyCard?.data?.organization}
              </div>
              <div className="w-100 d-flex justify-content-center">
                <div className="w-100 mt-1 d-flex justify-content-center">
                  <div
                    style={{
                      backgroundColor: "#ededed",
                      width: 100,
                      height: 100,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    {getMyCard?.data?.photo ? (
                      <div
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "white",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <img
                          src={getMyCard?.data?.photo}
                          style={{
                            width: 90,
                            height: 90,
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <LiaUser size={45} color="#F68A20" />
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 mt-2 d-flex justify-content-center">
                <div>
                  <div
                    className="w-100 d-flex justify-content-center"
                    style={{
                      fontSize: 14,
                      fontWeight: "700",
                      color: "black",
                    }}
                  >
                    {getMyCard?.data?.firstName} {getMyCard?.data?.lastName}
                  </div>
                  <div
                    className="w-100 d-flex justify-content-center"
                    style={{
                      fontSize: 14,
                      fontWeight: "400",
                      // color: "#F68A20",
                    }}
                  >
                    {getMyCard?.data?.jobTitle}
                  </div>
                  <div className="w-100 mt-2 mb-2 d-flex justify-content-center">
                    <div
                      style={{
                        height: 2,
                        width: 100,
                        background: "#F68A20",
                      }}
                    ></div>
                  </div>

                  <div className="w-100 d-flex justify-content-center">
                    <div
                      style={{
                        width: 120,
                        height: 120,
                        borderRadius: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <QRCode value={userID} size={100} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="Create Business Card"
        body={
          <CreateBusinessCard
            setIsOffcanvasOpen={setIsOffcanvasOpen}
            userId={userID}
            token={token}
          />
        }
        toggleSaveChanges={toggleSaveChanges}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
      <OffCanvas
        isOffcanvasOpen={isEditCard}
        title="Edit Business Card"
        body={
          <EditBusinessCard
            setIsOffcanvasOpen={setIsEditCard}
            userId={userID}
            token={token}
            getDetails={getMyCard?.data}
          />
        }
        toggleSaveChanges={toggleEditChanges}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
}

export default BusinessCard;
