import React, { useState } from "react";
import "./Chats.scss";
import SimpleBar from "simplebar-react";
// import { AiOutlineSend } from "react-icons/ai";
// import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
// import { GoSmiley } from "react-icons/go";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";

function Chats({}) {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userImg = obj?.data?.data?.photo;
  // console.log("obj...new", obj?.data?.data?.photo);
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  return (
    <>
      <MDBContainer
        style={{
          backgroundColor: "transparent",
          borderStyle: "solid",
          borderRadius: 10,
          borderWidth: 2,
          borderColor: "black",
          maxWidth: 600,
        }}
      >
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="12" lg="12" xl="12">
            <MDBCard id="chat2" style={{ borderRadius: "15px" }}>
              {/* <MDBCardHeader className="d-flex justify-content-between align-items-center p-3">
                <h5 className="mb-0">Chat</h5>
                <MDBBtn color="primary" size="sm" rippleColor="dark">
                  Let's Chat App
                </MDBBtn>
              </MDBCardHeader> */}
              <SimpleBar
                // suppressScrollX
                style={{ position: "relative", height: "400px" }}
              >
                <MDBCardBody>
                  <div className="d-flex flex-row justify-content-start">
                    <img
                      src={userImg}
                      style={{
                        width: "45px",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                    />
                    <div>
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3"
                        style={{ backgroundColor: "#f5f6f7" }}
                      >
                        Hi
                      </p>
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3"
                        style={{ backgroundColor: "#f5f6f7" }}
                      >
                        How are you ...???
                      </p>
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3"
                        style={{ backgroundColor: "#f5f6f7" }}
                      >
                        What are you doing tomorrow? Can we come up a bar?
                      </p>
                      <p className="small ms-3 mb-3 rounded-3 text-muted">
                        23:58
                      </p>
                    </div>
                  </div>

                  <div className="divider d-flex align-items-center mb-4">
                    <p
                      className="text-center mx-3 mb-0"
                      style={{ color: "#a2aab7" }}
                    >
                      Today
                    </p>
                  </div>

                  <div className="d-flex flex-row justify-content-start mb-4">
                    <img
                      src={userImg}
                      style={{
                        width: "45px",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                    />
                    <div>
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3"
                        style={{ backgroundColor: "#f5f6f7" }}
                      >
                        Okay i will meet you on Sandon Square
                      </p>
                      <p className="small ms-3 mb-3 rounded-3 text-muted">
                        00:11
                      </p>
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-end mb-4">
                    <div>
                      <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                        Do you have pictures of Matley Marriage?
                      </p>
                      <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
                        00:11
                      </p>
                    </div>
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
                      alt="avatar 1"
                      style={{ width: "45px", height: "100%" }}
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-start mb-4">
                    <img
                      src={userImg}
                      style={{
                        width: "45px",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                    />
                    <div>
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3"
                        style={{
                          backgroundColor: "#f5f6f7",
                          borderRadius: "50%",
                        }}
                      >
                        Sorry I don't have. i changed my phone.
                      </p>
                      <p className="small ms-3 mb-3 rounded-3 text-muted">
                        00:13
                      </p>
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-end">
                    <div>
                      <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                        Okay then see you on sunday!!
                      </p>
                      <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
                        00:15
                      </p>
                    </div>
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava4-bg.webp"
                      alt="avatar 1"
                      style={{ width: "45px", height: "100%" }}
                    />
                  </div>
                </MDBCardBody>
              </SimpleBar>
              <MDBCardFooter className="text-muted d-flex justify-content-start align-items-center p-3">
                <img
                  src={userImg}
                  alt="avatar 3"
                  style={{ width: "45px", height: "100%", borderRadius: "50%" }}
                />
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="exampleFormControlInput1"
                  placeholder="Type message"
                ></input>
                {/* <a className="ms-1 text-muted" href="#!">
                  <MDBIcon fas icon="paperclip" />
                </a> */}
                <a className="ms-3 text-muted" href="#!">
                  <MDBIcon fas icon="smile" />
                </a>
                <a className="ms-3" href="#!">
                  <MDBIcon fas icon="paper-plane" />
                </a>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default Chats;
