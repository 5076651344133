import React, { useState, useEffect } from "react";
import { eventCountDown } from "../../../../utils/eventCountDown";

import Divider from "./Divider";
// import GradientComponent from "../../GradientColor/GradientComponent";
import ButtonGradient from "../../../Buttons/ButtonGradient";
const About = ({ eventDetails }) => {
  const [countDown, setCountDown] = useState({ status: null, count: null });

  useEffect(() => {
    if (eventDetails) {
      const intervalId = setInterval(() => {
        const count = eventCountDown(
          eventDetails?.startDate,
          eventDetails?.endDate
        );
        setCountDown(count);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [eventDetails]);

  // console.log("eventDetails...", eventDetails);
  // console.log("countDown...", countDown);

  return (
    <React.Fragment>
      <div className="w-100 pt-3" style={{ backgroundColor: "white" }}>
        {/* <h1
          id="about"
          className="d-flex justify-content-center"
          style={{ color: "black", fontWeight: "bold" }}
        >
          About Event
        </h1> */}
        {/* <Divider /> */}

        <div className="w-100 mt-4 d-flex justify-content-center">
          <p style={{ width: "67%", textAlign: "center" }}>
            {eventDetails?.eventDescription}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
