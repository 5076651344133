import React, { useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Spinner } from "reactstrap";

const DeleteTicketForm = ({ setIsToggleDelete, eventID, ticketID, token }) => {
  const key = ["setTicket", eventID];
  const mutationDelete = useCustomMutation(key);
  const DeleteTicket = () => {
    const Method = "DELETE",
      url = `ticket-settings/${ticketID}/?event=${eventID}`;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Ticket successfully deleted",
      token: token,
    };
    mutationDelete.mutate(data);
  };

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete?.isSuccess]);

  return (
    <div className="w-100">
      <h5 className="text-danger">
        Are you sure you want to delete this Ticket ?
      </h5>
      <div className="modal-footer d-flex justify-content-between">
        <button
          onClick={() => {
            setIsToggleDelete(false);
          }}
          type="button"
          className="btn btn-dark"
        >
          Cancel
        </button>
        <button onClick={DeleteTicket} type="button" className="btn btn-danger">
          {!mutationDelete?.isLoading ? (
            <span className="px-2">Sure</span>
          ) : (
            <span>
              <Spinner as="span" animation="border" size="sm" />
              Loading
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default DeleteTicketForm;
