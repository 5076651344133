import React from "react";
import SimpleBar from "simplebar-react";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import { detectDeviceType } from "../../../utils/deviceType";
import Header from "./One/Header";
import About from "./One/About";
import Schedule from "./One/Schedule";
import Sponsors from "./One/Sponsors";
import OurSpeaker from "./One/Speakers";
import Faq from "./One/Faq";
import EventMap from "./One/EventMap";

// import ButtonStyle from "../../Buttons/ButtonSubmit";

const EventOne = ({ eventDetails, token, eventID }) => {
  const deviceType = detectDeviceType();

  const {
    data: getMySpeakers,
    error: errorGetMySpeakers,
    isLoading: isLoadingGetMySpeakers,
  } = useCustomFecthQuery(
    ["mySpeakers", eventID],
    `${process.env.REACT_APP_API}speakers/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomFecthQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getMySponsors,
    error: errorGetMySponsors,
    isLoading: isLoadingGetMySponsors,
  } = useCustomFecthQuery(
    ["mySponsors", eventID],
    `${process.env.REACT_APP_API}sponsors/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getMyBooths,
    error: errorGetMyBooths,
    isLoading: isLoadingGetMyBooths,
  } = useCustomFecthQuery(
    ["myBooths", eventID],
    `${process.env.REACT_APP_API}booths/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  return (
    <React.Fragment>
      <div className="new-event-landing-page">
        <Header
          eventDetails={eventDetails}
          deviceType={deviceType}
          isSessions={
            getMySessions?.status === "ok" && getMySessions?.data?.length === 0
          }
          isSpeaker={
            getMySpeakers?.status === "ok" && getMySpeakers?.data?.length === 0
          }
          isFaq={eventDetails?.faq?.Length > 0}
          isSponsors={
            getMySponsors?.status === "ok" &&
            getMySponsors?.data?.length === 0 &&
            getMyBooths?.status === "ok" &&
            getMyBooths?.data?.length === 0
          }
        />
        <div className="w-100">
          <SimpleBar style={{ height: "83vh" }}>
            <div className="new-event-landing-page-banner">
              {eventDetails?.bannerImage ? (
                <img src={eventDetails?.bannerImage} alt="Event Banner" />
              ) : null}
            </div>
            <div
              className="w-100 pt-3 pb-3"
              style={{ backgroundColor: "white" }}
            >
              <About eventDetails={eventDetails} />
            </div>
            {getMySessions?.status === "ok" &&
            getMySessions?.data?.length === 0 ? null : (
              <div className="mt-4">
                <Schedule
                  eventDetails={eventDetails}
                  token={token}
                  eventID={eventID}
                />
              </div>
            )}
            {getMySpeakers?.status === "ok" &&
            getMySpeakers?.data?.length === 0 ? null : (
              <div
                className="d-flex justify-content-center mt-4 pt-3 pb-4"
                style={{ backgroundColor: "white" }}
              >
                <div className="" style={{ width: "1000px" }}>
                  <OurSpeaker
                    token={token}
                    eventID={eventID}
                    getMySpeakers={getMySpeakers}
                  />
                </div>
              </div>
            )}
            {getMySponsors?.status === "ok" &&
            getMySponsors?.data?.length === 0 ? null : (
              <div className="mt-4">
                <Sponsors token={token} eventID={eventID} />
              </div>
            )}
            {eventDetails?.faq?.Length > 0 ? (
              <div className="mt-4" style={{ backgroundColor: "white" }}>
                <Faq
                  token={token}
                  eventID={eventID}
                  eventDetails={eventDetails}
                />
              </div>
            ) : null}
            {eventDetails?.venueMap ? (
              <div className="mt-4" style={{ backgroundColor: "white" }}>
                <EventMap venueMap={eventDetails?.venueMap} />
              </div>
            ) : null}
          </SimpleBar>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventOne;
