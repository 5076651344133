import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../../../store/actions";
import usePost from "../../../../Hooks/usePost";
import { Spinner, Row, Col } from "reactstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Logo from "../../../../components/Authentication/Logo";
import ButtonSubmit from "../../../../components/Buttons/ButtonSubmit";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default function SignUpForm({ props }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { execute, pending, data, status } = usePost();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    agreeToTerms: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCheckboxChange = () => {
    setValues({ ...values, agreeToTerms: !values.agreeToTerms });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const passwordsMatch = values.password === values.confirmPassword;

  const handleSignUp = () => {
    if (values.agreeToTerms && passwordsMatch) {
      const Method = "POST",
        endPoint = "auth/attendee",
        token = null;
      const raw = JSON.stringify({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        password: values.password,
        passwordConfirm: values.confirmPassword,
      });
      execute(endPoint, raw, Method, "Successfully signed up", token);
    }
  };

  useEffect(() => {
    if (status == "success") {
      navigate("/message");
    }
  }, [status]);

  return (
    <div className="signup-form-container">
      <div className="signup-form-header">
        <Logo />
        <h1>Sign Up</h1>
        <p>Register to create your account</p>
      </div>
      <Row>
        <Col md={6}>
          <div className="form-field">
            <label>
              <span className="text-danger">*</span>First Name
            </label>
            <input
              type="text"
              value={values.firstName}
              onChange={handleChange("firstName")}
              name="firstName"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form-field">
            <label>
              <span className="text-danger">*</span>Last Name
            </label>
            <input
              type="text"
              value={values.lastName}
              onChange={handleChange("lastName")}
              name="lastName"
            />
          </div>
        </Col>
        <Col md={12}>
          <label>
            <span className="text-danger">*</span>Phone Number
          </label>
          <div className="phone-input-container">
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="ZA"
              value={values.phoneNumber ? values.phoneNumber : null}
              onChange={(value) => setValues({ ...values, phoneNumber: value })}
              className="custom-phone-input"
            />
          </div>
        </Col>
        <div className="form-field">
          <label>
            <span className="text-danger">*</span>Email
          </label>
          <input
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            className={!validateEmail(values.email) ? "error" : ""}
            name="email"
          />
          {!validateEmail(values.email) && (
            <p className="mt-1 text-primary">Enter a valid email</p>
          )}
        </div>
        <div className="form-field">
          <label>Password</label>
          <div className="password-input">
            <input
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              style={{ borderRadius: "10px" }}
              name="password"
            />
            <button
              className="eye-icon"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <FiEye /> : <FiEyeOff />}
            </button>
          </div>
        </div>
        <div className="form-field">
          <label>Confirm Password</label>
          <input
            type={values.showPassword ? "text" : "password"}
            value={values.confirmPassword}
            onChange={handleChange("confirmPassword")}
            name="confirmPassword"
          />
          {!passwordsMatch && (
            <p className="mt-1 text-danger">Passwords do not match</p>
          )}
        </div>
      </Row>
      <div
        className="form-field"
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <input
          type="checkbox"
          checked={values.agreeToTerms}
          onChange={handleCheckboxChange}
          style={{ marginRight: "6px" }}
        />
        <label style={{ marginTop: 8 }}>Agree to Terms and Conditions</label>
      </div>
      <div className="d-flex justify-content-center">
        <ButtonSubmit
          Title="Sign Up"
          BackgroundColor="#f68a20"
          ColorText="#fff"
          BorderColor="#f68a20"
          borderRadius={25}
          handleOnclick={handleSignUp}
          pending={pending}
          disabled={
            !values.agreeToTerms ||
            !passwordsMatch ||
            !validateEmail(values.email)
          }
        />
      </div>
    </div>
  );
}
