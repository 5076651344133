import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import SetEventHeader from "../../Layout/HorizontalLayout/SetEventHeader";
import CustomFooter from "../../Layout/HorizontalLayout/CustomFooter";
import logo from "../../assets/images/community/Logo.png";
import { Box } from "@mui/material";
import { Sidebar } from "../../components/EventSetUp/Layout/Sidebar/Sidebar";
import { Content } from "../../components/EventSetUp/Layout/Content/Content";
import WhiteLogo from "../../assets/images/kago-color-white.png";

function EventSetUp() {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userID = obj?.data?.data?._id;
  const token = obj?.data?.token;
  const userEmail = obj?.data?.data?.email;
  const params = useParams();
  const eventID = params.eventID;
  const [selectActiveTab, setSelectActiveTab] = useState("1");

  const {
    data: getEventDetails,
    error: errorGetEventDetails,
    isLoading: isLoadingGetEventDetails,
  } = useCustomFecthQuery(
    ["EventDetails", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <img
        src={WhiteLogo}
        // className="w-100 h-100"
        style={{
          position: "fixed",
          top: 0,
          left: 35,
          width: 130,
          height: 135,
          zIndex: 999,
        }}
      />
      <SetEventHeader
        logoImg={logo}
        communityID={getEventDetails?.data?.communityId?._id}
        eventID={eventID}
        userEmail={userEmail}
        getEventDetails={getEventDetails}
      />
      <Box sx={{ backgroundColor: "#282e3f", mt: 0, p: 4 }} />
      {/* <div
        className="event-sidebar-index"
        style={{
          position: "absolute",
          top: 0,
          left: 35,
          width: 130,
          height: 135,
          // zIndex: 100,
        }}
      >
        <img src={WhiteLogo} className="w-100 h-100" />
      </div> */}
      <div className="w-100 h-100 d-flex" style={{ position: "relative" }}>
        <div
          className="h-100"
          style={{
            width: "20%",
            position: "fixed",
            backgroundColor: "#000",
          }}
        >
          <Sidebar
            getEventDetails={getEventDetails}
            selectActiveTab={selectActiveTab}
            setSelectActiveTab={setSelectActiveTab}
          />
        </div>
        <div className="w-100 d-flex justify-content-center" style={{}}>
          <div className="" style={{ width: "20%" }}></div>
          <div className="" style={{ width: "80%" }}>
            <Content
              getEventDetails={getEventDetails}
              selectActiveTab={selectActiveTab}
              eventDetails={getEventDetails?.data}
              token={token}
              userID={userID}
              isLoading={isLoadingGetEventDetails}
            />
          </div>
        </div>
      </div>

      <div className="w-100">
        <CustomFooter />
      </div>
    </div>
  );
}

export default EventSetUp;
